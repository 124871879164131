import {
  useContext,
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { AppContext, ServiceContext } from "../../Contexts/Contexts";
import ExtractionUtils from "../../utils/extraction.utils";
import SaveIcon from "../../components/Icons/SaveIcon";
import ApiClient from "../../clients/api.client";
import ExtractionPanel from "./Panels/extraction.panel";
import { copy, sleep } from "../../utils/general.utils";
import ArrayUtils from "../../utils/array.utils";
import StringUtils from "../../utils/string.utils";
import DomUtils from "../../utils/dom.utils";
import BrowserUtils from "../../utils/browser.utils";

const tempFieldProps = new Set(["config", "ref", "rowIdx", "colIdx", "index"]);

const ExtractionService = () => {
  const { showCheckmark, alert, workflows } = useContext(AppContext);
  const { state, dispatch } = useContext(ServiceContext);
  const loan = useMemo(() => state.loan, [state.loan]);
  const pages = useMemo(
    () => (state.loan ? ArrayUtils.createIndex(state.loan.pages, "_id") : null),
    [state.loan]
  );
  const [document, setDocument] = useState(null);
  const rowRef = useRef();
  const [currentField, setCurrentField] = useState(null);
  const workflow = useMemo(() => {
    const workflow = workflows?.find((w) => w.name === "default");
    if (!workflow) return null;
    const documents = ArrayUtils.createIndex(workflow.documents, "type");
    return {
      ...workflow,
      documents,
    };
  }, [workflows]);
  const page = useMemo(() => {
    const documentPage = document?.pages.find(
      (page) => page._id === currentField?.pageId
    );
    if (!documentPage) return null;
    const sourcePage = pages[documentPage?._id];
    return {
      ...sourcePage,
      ...documentPage,
      src: BrowserUtils.getImageUrl(loan._id, sourcePage?.imageId),
    };
  }, [document, currentField, loan, pages]);

  // GetSetters
  const getSetDocuments = useCallback(() => {
    if (!loan) return;
    const documents = loan.documents;
    documents.forEach((doc, idx) => {
      doc.explorer = {
        type: "document",
        name: `${doc.type} (${StringUtils.getShortHash(
          doc._id
        ).toUpperCase()})`,
        tag: DomUtils.getVersionTag(doc.version),
      };
      doc.index = idx;
      doc.pages.forEach((page) => {
        page.src = BrowserUtils.getImageUrl(loan._id, pages[page._id].imageId);
      });
    });
    dispatch({ type: "setRepo", repo: documents });
    return documents;
  }, [dispatch, loan, pages]);

  const getSetDocument = useCallback(() => {
    const document = copy(state.item);
    document.rows = [];
    setDocument(document);
    const docConfig = workflow.documents[document.type.toLowerCase()];
    document.rows = copy(docConfig.extractionRows);
    const indexedFields = ArrayUtils.createIndex(document.fields, "name");
    const fieldConfigs = ArrayUtils.createIndex(docConfig.fields, "name");
    document.rows.forEach((row, rowIdx) => {
      if (row.type !== "fields") return;
      const fields = row.fieldNames.map((fieldName, colIdx) => {
        const field = indexedFields[fieldName];
        const fc = fieldConfigs[fieldName];
        if (!field) {
          console.error(`Field: "${fieldName}" not found in loan.`);
          return null;
        }
        if (fc.threshold === null)
          fc.threshold = workflow.extraction.defaultThreshold;
        return {
          ...field,
          config: fc,
          rowIdx,
          colIdx,
        };
      });
      row.fields = fields.filter((field) => field !== null);
    });
    setDocument({ ...document });
  }, [state.item, workflow]);

  // Actions
  const approveField = useCallback(
    (value, originalField) => {
      document.rows.forEach((row) => {
        if (row.type !== "fields") return;
        row.fields.forEach((field) => {
          if (field.name !== originalField.name) return;
          field.value = value;
          field.confidence = 201;
        });
      });
      setDocument({ ...document });
    },
    [document]
  );

  // Clicks
  const onFocus = useCallback((field) => {
    setCurrentField(field);
  }, []);

  const onFieldChange = useCallback(
    (value, originalField) => approveField(value, originalField),
    [approveField]
  );

  const onSave = useCallback(async () => {
    const fields = ExtractionUtils.getFlattenedFields(document.rows).map(
      (_field) => {
        const field = {};
        Object.keys(_field).forEach((key) => {
          if (tempFieldProps.has(key)) return;
          field[key] = _field[key];
        });
        return field;
      }
    );
    state.item.fields = fields;
    await ApiClient.putDocument(loan._id, copy(state.item));
    await showCheckmark();
  }, [loan, state.item, document, showCheckmark]);

  const Buttons = useMemo(() => {
    return [
      <SaveIcon size={50} className={"button pad-10"} onClick={onSave} />,
    ];
  }, [onSave]);

  const hotkeys = useCallback(
    async (e) => {
      if (!document || DomUtils.hasPopup()) return;
      if (e.ctrlKey) {
        switch (e.key) {
          case "s":
            onSave();
            break;
          default:
            break;
        }
      } else {
        switch (e.key) {
          case "Enter":
          case "Tab":
            if (e.key === "Enter") {
              approveField(currentField.value, currentField);
            }
            const lowConfFields = ExtractionUtils.getLowConfFields(
              document.rows
            );
            if (lowConfFields.length === 0) {
              alert.open(<div>No Fields to Review</div>);
              return;
            }
            const getField = (direction) => {
              const currentRowIdx = currentField?.rowIdx ?? -1;
              const currentColIdx = currentField?.colIdx ?? -1;

              const isNextValid = (field) =>
                (field.rowIdx === currentRowIdx &&
                  field.colIdx > currentColIdx) ||
                field.rowIdx > currentRowIdx;

              const isPrevValid = (field) =>
                (field.rowIdx === currentRowIdx &&
                  field.colIdx < currentColIdx) ||
                field.rowIdx < currentRowIdx;

              if (direction === "next") {
                return lowConfFields.find(isNextValid) || lowConfFields[0];
              }

              if (direction === "prev") {
                for (let i = lowConfFields.length - 1; i >= 0; i--) {
                  if (isPrevValid(lowConfFields[i])) return lowConfFields[i];
                }
                return lowConfFields[lowConfFields.length - 1];
              }

              return null;
            };

            const direction = e.shiftKey ? "prev" : "next";
            let field = getField(direction);
            rowRef.current.scrollToItem(field.rowIdx, "center");
            await sleep(0.1); // time for scrolled items to render
            field.ref.focus();
            break;
          default:
            break;
        }
      }
    },
    [onSave, approveField, currentField, alert, document]
  );

  useEffect(() => {
    const driver = () => {
      getSetDocuments();
    };
    driver();
  }, [getSetDocuments]);

  useEffect(() => {
    const itemDriver = () => {
      if (state.item && state.item._id !== document?._id) {
        getSetDocument();
        dispatch({ type: "setButtons", buttons: Buttons });
      }
    };
    itemDriver();
  }, [state.item, document, dispatch, Buttons, getSetDocument]);

  useEffect(() => {
    window.addEventListener("keydown", hotkeys);
    return () => window.removeEventListener("keydown", hotkeys);
  }, [hotkeys]);

  return (
    <ExtractionPanel
      document={document}
      page={page}
      onFieldChange={onFieldChange}
      onFocus={onFocus}
      rowRef={rowRef}
      field={currentField}
    />
  );
};

export default ExtractionService;
