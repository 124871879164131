import { useNavigate } from "react-router";
import LoanTableConfig from "./config/loanTable.config";
import StandardDataGrid from "../../components/DataGrids/StandardDataGrid";
import { useCallback, useContext, useMemo } from "react";
import InfoIcon from "../../components/Icons/InfoIcon";
import ClassificationIcon from "../../components/Icons/ClassificationIcon";
import VersioningIcon from "../../components/Icons/VersioningIcon";
import ExtractionIcon from "../../components/Icons/ExtractionIcon";
import IncomeIcon from "../../components/Icons/IncomeIcon";
import AuditIcon from "../../components/Icons/AuditIcon";
import DeleteIcon from "../../components/Icons/DeleteIcon";
import { AppContext } from "../../Contexts/Contexts";
import ApiClient from "../../clients/api.client";

const initialState = {
  sorting: {
    sortModel: [{ field: "dateAdded", sort: "desc" }],
  },
};

const LoanTable = ({ loans }) => {
  const navigate = useNavigate();
  const { confirm, loader, alert } = useContext(AppContext);

  // actions
  const deleteLoan = useCallback(
    async (loan) => {
      loader.open();
      try {
        await ApiClient.deleteLoan(loan._id);
      } catch (error) {
        loader.close();
        alert.open(<div>{error.toString()}</div>);
        console.error(error);
      } finally {
        loader.close();
      }
    },
    [alert, loader]
  );

  // onClicks
  const onLoanSelected = useCallback(
    (loan, service) => {
      navigate(`/loan?id=${loan._id}&service=${service}`);
    },
    [navigate]
  );

  const onDeleteLoan = useCallback(
    (loan) => {
      confirm.open(
        <div>Are you sure you want to delete loan: {loan.name}?</div>,
        () => deleteLoan(loan)
      );
    },
    [confirm, deleteLoan]
  );

  const columns = useMemo(
    () =>
      LoanTableConfig.map((column) => {
        switch (column.field) {
          case "services":
            column.maxWidth = 200;
            column.renderCell = (params) => {
              const {
                documentCount,
                fieldCount,
                lowConfidenceDocumentCount,
                lowConfidenceVersionCount,
                lowConfidenceFieldCount,
              } = params.row;
              const className = "button br-5 pad-2 box-shadow-c";
              const iconSize = 25;
              const cBg = lowConfidenceDocumentCount
                ? "bg-red"
                : documentCount
                ? "bg-green"
                : "";
              const vBg = lowConfidenceVersionCount ? "bg-red" : "";
              const eBg = lowConfidenceFieldCount
                ? "bg-red"
                : fieldCount
                ? "bg-green"
                : "";
              const iBg = "";
              const aBg = "";
              return (
                <div className="flex gap-5">
                  <InfoIcon
                    size={iconSize}
                    className={`pad-2 button`}
                    onClick={() => onLoanSelected(params.row, "details")}
                  />
                  <ClassificationIcon
                    size={iconSize}
                    className={`${className} ${cBg}`}
                    onClick={() => onLoanSelected(params.row, "classification")}
                  />
                  <VersioningIcon
                    size={iconSize}
                    className={`${className} ${vBg}`}
                    onClick={() => onLoanSelected(params.row, "versioning")}
                  />
                  <ExtractionIcon
                    size={iconSize}
                    className={`${className} ${eBg}`}
                    onClick={() => onLoanSelected(params.row, "extraction")}
                  />
                  <IncomeIcon
                    size={iconSize}
                    className={`${className} ${iBg}`}
                    onClick={() => onLoanSelected(params.row, "income")}
                  />
                  <AuditIcon
                    size={iconSize}
                    className={`${className} ${aBg}`}
                    onClick={() => onLoanSelected(params.row, "audit")}
                  />
                </div>
              );
            };
            break;
          case "delete":
            column.renderCell = (params) => {
              return (
                <DeleteIcon
                  size={25}
                  className={"button pad-2 bg-red br-5 box-shadow-c"}
                  onClick={() => onDeleteLoan(params.row)}
                />
              );
            };
            break;
          default:
            break;
        }
        return column;
      }),
    [onLoanSelected, onDeleteLoan]
  );

  return (
    <StandardDataGrid
      name="loanTable"
      rows={loans}
      columns={columns}
      initialState={initialState}
    />
  );
};

export default LoanTable;
