const SubmitInput = ({ value, sx, disabled }) => {
  return (
    <button
      className="candy-button tertiary-color-scheme-1"
      style={sx}
      disabled={disabled}
    >
      <input type="submit" value={value} />
    </button>
  );
};

SubmitInput.defaultProps = {
  sx: {},
  disabled: false,
};

export default SubmitInput;
