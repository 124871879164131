import { ServiceContext } from "../../Contexts/Contexts";
import { useContext, useMemo, useCallback, useEffect, useState } from "react";
import ApiClient from "../../clients/api.client";
import AuditPanel from "./Panels/audit.panel";
import DomUtils from "../../utils/dom.utils";
import StringUtils from "../../utils/string.utils";
import ArrayUtils from "../../utils/array.utils";

const AuditService = () => {
  const { state, dispatch } = useContext(ServiceContext);
  const indexedLoan = useMemo(
    () => (state.loan ? ArrayUtils.getIndexedLoan(state.loan) : null),
    [state.loan]
  );
  const [report, setReport] = useState(null);

  const getSetReports = useCallback(() => {
    state.loan?.auditReports.sort((a, b) => {
      const dateA = new Date(a.dateAdded);
      const dateB = new Date(b.dateAdded);
      return dateB - dateA;
    });
    state.loan?.auditReports.forEach((report, index) => {
      report.explorer = {
        type: "document",
        name: StringUtils.indexToLetter(index),
        tag: DomUtils.getDateTag(report.dateAdded),
      };
      report.index = index;
    });
    dispatch({ type: "setRepo", repo: state.loan?.auditReports });
    return state.loan?.auditReports;
  }, [state.loan?.auditReports, dispatch]);

  const getSetReport = useCallback(async (loanId, reportId) => {
    const report = await ApiClient.getAuditReports(loanId, reportId);
    setReport(report);
    return report;
  }, []);

  useEffect(() => {
    getSetReports();
  }, [getSetReports]);

  useEffect(() => {
    if (state.loan && state.item) {
      getSetReport(state.loan?._id, state.item._id);
    }
  }, [getSetReport, state.item, state.loan]);

  return <AuditPanel report={report} indexedLoan={indexedLoan} />;
};

export default AuditService;
