import { isEmpty } from "./general.utils";
import ObjectUtils from "./object.utils";

class CompareUtils {
  static descendingComparator(a, b, orderBy, orderType = null, order) {
    let x = ObjectUtils.getNestedValue(a, orderBy);
    let y = ObjectUtils.getNestedValue(b, orderBy);

    if (orderBy === "priority") {
      const priority = {
        High: 100,
        Medium: 200,
        Low: 300,
      };
      x = priority[x];
      y = priority[y];
    } else {
      switch (orderType) {
        case "date":
          x = new Date(x);
          y = new Date(y);
          break;
        case "number":
          x = parseInt(x);
          y = parseInt(y);
          break;
        default:
          x = x?.toLowerCase();
          y = y?.toLowerCase();
          break;
      }
    }
    if (isEmpty(y)) {
      return order === "desc" ? -1 : 1;
    }
    if (isEmpty(x)) {
      return order === "desc" ? 1 : -1;
    }
    if (y < x) {
      return -1;
    }
    if (y > x) {
      return 1;
    }
    return 0;
  }

  static getComparator(order, orderBy, orderType = null) {
    return order === "desc"
      ? (a, b) =>
          CompareUtils.descendingComparator(a, b, orderBy, orderType, order)
      : (a, b) =>
          -CompareUtils.descendingComparator(a, b, orderBy, orderType, order);
  }
}

export default CompareUtils;
