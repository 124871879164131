import IncomeReportMetaData from "../IncomeComponents/IncomeReportMetaData";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import IncomeDocTable from "../IncomeComponents/IncomeDocTable";
import MonthlyIncomeTable from "../IncomeComponents/MonthlyIncomeTable";
import VerificationTable from "../IncomeComponents/VerificationTable";
import TableConfig from "../IncomeComponents/TableConfig";
import { useState } from "react";

const toggleButtonProps = {
  fontWeight: "bold",
  color: "black",
  textTransform: "none",
  backgroundColor: "white",
  margin: "2px",
  borderRadius: "0",
};

const IncomePanel = ({ report, indexedLoan }) => {
  const [table, setTable] = useState("docs");

  return (
    <div className="s-full relative">
      <div
        id={report?._id}
        className={`flex-col gap-5 h-full overflow-hidden ${
          report ? "" : "block-events"
        }`}
      >
        <IncomeReportMetaData report={report} />
        <div className="bg-lg h-full pad-5 flex-col gap-5 overflow-hidden">
          <ToggleButtonGroup
            value={table}
            exclusive
            onChange={(e, value) => setTable(value)}
            size="small"
            className="secondary-color-scheme"
            sx={{
              borderRadius: "0",
              width: "fit-content",
            }}
          >
            <ToggleButton value="docs" sx={toggleButtonProps}>
              Documents
            </ToggleButton>
            <ToggleButton value="income" sx={toggleButtonProps}>
              Monthly Income
            </ToggleButton>
            <ToggleButton value="alerts" sx={toggleButtonProps}>
              Alerts
            </ToggleButton>
            <ToggleButton value="warnings" sx={toggleButtonProps}>
              Warnings
            </ToggleButton>
            <ToggleButton value="verified" sx={toggleButtonProps}>
              Verified
            </ToggleButton>
          </ToggleButtonGroup>
          <IncomeDocTable
            loan={indexedLoan}
            docs={report?.documents}
            hidden={table !== "docs"}
          />
          <MonthlyIncomeTable
            loan={indexedLoan}
            incomeTypes={report?.incomeTypes}
            hidden={table !== "income"}
          />
          <VerificationTable
            loan={indexedLoan}
            verifications={report?.verifications.filter(
              (verification) =>
                !verification.result && verification.priority < 200
            )}
            hidden={table !== "alerts"}
            tableConfig={TableConfig.alertsAndWarnings}
          />
          <VerificationTable
            loan={indexedLoan}
            verifications={report?.verifications.filter(
              (verification) =>
                !verification.result && verification.priority >= 200
            )}
            hidden={table !== "warnings"}
            tableConfig={TableConfig.alertsAndWarnings}
          />
          <VerificationTable
            loan={indexedLoan}
            verifications={report?.verifications.filter(
              (verification) => verification.result
            )}
            hidden={table !== "verified"}
            tableConfig={TableConfig.verified}
          />
        </div>
      </div>
      {!report && (
        <div className="absolute bg-blue white box-shadow pad-10 br-5 serif font-24 transform-center">
          Select a report from the list.
        </div>
      )}
    </div>
  );
};

export default IncomePanel;
