import { Autocomplete, TextField } from "@mui/material";
import Confidence from "../Fields/Confidence";
import Viewer from "../Viewers/Viewer";
import textFieldProps from "../../store/textfield.props";
import StandardAttribute from "../Attributes/StandardAttribute";
import { useContext } from "react";
import { AppContext } from "../../Contexts/Contexts";

const VersioningDocument = ({ document, onVersion }) => {
  const { workflows } = useContext(AppContext);
  const workflow = workflows.find((workflow) => workflow.name === "default");
  const versioningConfig = workflow.versioning;
  const documentConfig = workflow.documents.find(
    (doc) => doc.type === document.type.toLowerCase()
  );
  return (
    <div className="flex-col gap-5 h-full">
      <div className="flex gap-5">
        <StandardAttribute
          className={"font-14 bg-white br-5 pad-5 box-shadow-c"}
          sx={{ minWidth: "85px" }}
          label="Name"
          data={document.explorer.name}
        />
        <Autocomplete
          size="small"
          openOnFocus
          options={versioningConfig.labels}
          // getOptionDisabled={(option) => option.disabled}
          // isOptionEqualToValue={(option, value) => option.value === value}
          renderInput={(params) => (
            <TextField
              {...params}
              className="br-5 box-shadow-c"
              variant="filled"
              label="Select Version..."
              // inputRef={(input) => (autocompleteRef.current = input)}
              InputLabelProps={{
                style: {
                  color: "black",
                  fontWeight: "bold",
                  fontFamily: "var(--true-font-family-sans-serif)",
                  fontSize: "18px",
                },
                shrink: true,
              }}
              InputProps={{
                ...params.InputProps,
                style: {
                  backgroundColor: "white",
                  fontFamily: "var(--true-font-family-serif)",
                },
              }}
            />
          )}
          autoComplete
          autoHighlight
          style={{
            ...textFieldProps,
            minWidth: "200px",
            width: "100%",
          }}
          value={document.version || ""}
          onChange={(e, value) => onVersion(document, value)}
          // disabled={disabled}
          // onFocus={() => autocompleteRef.current.select()}
        />
        <Confidence
          className={"bg-white br-5 box-shadow-c"}
          confidence={document.versionConfidence}
          threshold={
            documentConfig?.threshold ?? versioningConfig.defaultThreshold
          }
        />
      </div>
      <Viewer images={document.pages} index={0} sx={{ width: "500px" }} />
    </div>
  );
};

VersioningDocument.defaultPropTypes = {
  document: {},
  onVersion: () => {},
};

export default VersioningDocument;
