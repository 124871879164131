import { useCallback, useEffect, useRef, useState } from "react";
import ApiClient from "../../clients/api.client";
import LoanTable from "./LoanTable";
import AddLoan from "./components/AddLoan";
import { useContext } from "react";
import { AppContext } from "../../Contexts/Contexts";
import BatchIcon from "../../components/Icons/BatchIcon";
import AddIcon from "../../components/Icons/AddIcon";
import ArrayUtils from "../../utils/array.utils";
import DomUtils from "../../utils/dom.utils";
import Vars from "../../store/vars";

const LoanDashboard = () => {
  const { leftPopOut } = useContext(AppContext);
  const [data, setData] = useState([]);
  const fetching = useRef(false);

  //get-setters
  const getSetData = useCallback(async () => {
    if (fetching.current) return;
    try {
      fetching.current = true;
      const [loans, jobs] = await Promise.all([
        ApiClient.getLoans(),
        ApiClient.getJobs(),
      ]);
      const activeJobs = jobs.filter(
        (job) => job.status === "pending" || job.status === "running"
      );
      const jobsByLoanId = ArrayUtils.groupBy(activeJobs, "request.loanId");
      const data = loans.map((loan) => {
        return {
          ...loan,
          activeJobs: jobsByLoanId[loan._id]?.length || 0,
        };
      });
      setData(data);
      return data;
    } finally {
      fetching.current = false;
    }
  }, []);

  useEffect(() => {
    getSetData();
    const id = setInterval(getSetData, Vars.refreshInterval);
    return () => clearInterval(id);
  }, [getSetData]);

  const Banner = () => {
    return (
      <div className="flex gap-5">
        <div className="flex w-full bg-green pad-5-10 align-center gap-5 h-50">
          <BatchIcon size={30} />
          <h1 className="font-24">Loans</h1>
        </div>
        <div>
          <AddIcon
            title="Create Loan"
            className="button bg-orange s-full pad-10"
            onClick={() =>
              leftPopOut.open(
                DomUtils.getOrangeBlackHeader("Create", "Loan"),
                <AddLoan loans={data} />
              )
            }
          />
        </div>
      </div>
    );
  };

  return (
    <div className="pad-10 h-full w-full">
      <div className="h-full w-full flex-col border-top-blue bg-white box-shadow gap-5 pad-5">
        {Banner()}
        <LoanTable loans={data} />
      </div>
    </div>
  );
};

export default LoanDashboard;
