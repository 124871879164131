import { useState } from "react";
import FolderOpenIcon from "../Icons/FolderOpenIcon";
import FolderCloseIcon from "../Icons/FolderCloseIcon";
import CircleIcon from "../Icons/CircleIcon";

const Folder = ({ _this, index, selectedIndex, onClick, hidden }) => {
  const selected =
    index.outer === selectedIndex.outer && index.inner === selectedIndex.inner;
  const [hover, setHover] = useState(false);
  const size = 25;

  const getBgColor = () => (selected || hover ? "bg-lg" : "");

  return (
    !hidden && (
      <div className="flex-col">
        <div
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={() => {
            onClick(_this, index);
          }}
          className={`flex align-center gap-5 pointer pad-0-5 ${getBgColor()}`}
          title={_this.explorer.name}
        >
          {selected ? (
            <FolderOpenIcon size={size} />
          ) : (
            <FolderCloseIcon size={size} />
          )}
          <div className="ellipses flex align-center gap-5">
            {_this.explorer.tag}
            <div className="ellipses">{_this.explorer.name}</div>
          </div>
        </div>
        {selected && (
          <div className="pad-l-10">
            {_this.documents.map((document, innerIdx) => (
              <div key={innerIdx} className="flex align-center gap-5 pad-5">
                <CircleIcon
                  size={size}
                  className={"bg-lg blue button br-20"}
                  selected={!document.explorer.hidden}
                  onClick={() => {
                    document.explorer.hidden = !document.explorer.hidden;
                    onClick({ ..._this }, index);
                  }}
                />
                <div className="ellipses">
                  <div className="ellipses font-14">
                    {document.explorer.name}
                  </div>
                  {document.explorer.tag && (
                    <div className="ellipses">{document.explorer.tag}</div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  );
};

Folder.defaultProps = {
  name: "Folder",
  onClick: () => {},
  selected: false,
  documents: [],
  selectedIndex: { outer: -1, inner: -1 },
  hidden: false,
};

export default Folder;
