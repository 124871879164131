import PropTypes from "prop-types";
import { CgCloseO } from "react-icons/cg";

const CircleCloseIcon = ({ onClick, size, className }) => {
  return (
    <CgCloseO className={`icon ${className}`} onClick={onClick} size={size} />
  );
};

CircleCloseIcon.defaultProps = {
  size: 35,
  className: "",
};

CircleCloseIcon.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
  className: PropTypes.string,
};

export default CircleCloseIcon;
