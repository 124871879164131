import { FaInfoCircle } from "react-icons/fa";
import PropTypes from "prop-types";

const InfoIcon = ({ onClick, size, sx, className, title }) => {
  return (
    <FaInfoCircle
      className={`icon ${className}`}
      size={size}
      onClick={onClick}
      style={sx}
      title={title}
    />
  );
};

InfoIcon.defaultProps = {
  size: 30,
  onClick: () => {},
  sx: {},
  className: "",
  title: "",
};

InfoIcon.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
};

export default InfoIcon;
