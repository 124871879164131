import { FormControlLabel, Switch } from "@mui/material";
import React, { useCallback, useContext, useState } from "react";
import StandardTable from "../../../components/Tables/StandardTable";
import TableConfig from "./TableConfig";
import { AppContext } from "../../../Contexts/Contexts";
import Viewer from "../../../components/Viewers/Viewer";
import DomUtils from "../../../utils/dom.utils";
import BrowserUtils from "../../../utils/browser.utils";

const IncomeDocTable = ({ loan, docs, hidden }) => {
  const { leftPopOut } = useContext(AppContext);
  const [showAll, setShowAll] = useState(false);

  const onDocSelected = async (docUsed) => {
    const doc = loan.documents[docUsed._id];
    const pages = Object.values(doc.pages).map((page) => {
      return {
        ...page,
        src: BrowserUtils.getImageUrl(loan.name, page.fileId),
      };
    });
    leftPopOut.open(
      DomUtils.getOrangeBlackHeader("Document", doc.type),
      <Viewer images={pages} className={"w-half-screen"} />
    );
  };

  const getRows = useCallback(() => {
    const rows = showAll ? docs : docs?.filter((doc) => doc.used);
    return rows?.map((docFromReport) => {
      const doc = loan.documents[docFromReport._id];
      return {
        ...doc,
        used: docFromReport.used,
      };
    });
  }, [loan, docs, showAll]);

  return (
    <div className="overflow-hidden box-shadow bg-white" hidden={hidden}>
      <div className="flex">
        <FormControlLabel
          control={
            <Switch
              checked={showAll}
              onChange={(e) => setShowAll(e.target.checked)}
            />
          }
          label="Show Not Used"
          labelPlacement="start"
        />
      </div>
      <StandardTable
        tableConfig={TableConfig.docs}
        rowData={getRows()}
        onIconClicks={{ view: onDocSelected }}
      />
    </div>
  );
};

export default IncomeDocTable;
