import { InputAdornment, TextField } from "@mui/material";
import { AiOutlineSearch } from "react-icons/ai";

const SearchBar = ({
  placeholder,
  value,
  onChange,
  sx,
  onKeyDown,
  className,
  inputProps,
}) => {
  return (
    <TextField
      className={className}
      size="small"
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={onKeyDown}
      sx={sx}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" className="button">
            <AiOutlineSearch
              size={20}
              color="#757575"
              onClick={() =>
                onKeyDown({ key: "Enter", target: { value: value } })
              }
            />
          </InputAdornment>
        ),
      }}
      inputProps={inputProps}
    />
  );
};

SearchBar.defaultProps = {
  onKeyDown: () => {},
  onChange: () => {},
  placeholder: "Search",
  value: "",
  sx: {},
  className: "",
  inputProps: {},
};

export default SearchBar;
