import ApiClient from "../../clients/api.client";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// import UploadLos from "../../components/Upload/UploadLos";
import StandardAttribute from "../../components/Attributes/StandardAttribute";
import { useLocation, useNavigate } from "react-router-dom";
import JobDetails from "./JobDetails";
import { AppContext, ServiceContext } from "../../Contexts/Contexts";
import AddIcon from "../../components/Icons/AddIcon";
import DeleteIcon from "../../components/Icons/DeleteIcon";
import AddFiles from "./components/AddFiles";
import Viewer from "../../components/Viewers/Viewer";
import Services from "../../store/services";
import LosIcon from "../../components/Icons/LosIcon";
import BrowserUtils from "../../utils/browser.utils";
import ArrayUtils from "../../utils/array.utils";
import DomUtils from "../../utils/dom.utils";
import Vars from "../../store/vars";

const threshold = 80;

const LoanDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { leftPopOut, confirm, loader, showCheckmark, alert } =
    useContext(AppContext);
  const { state, dispatch } = useContext(ServiceContext);
  const fetching = useRef(false);
  const [loan, setLoan] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [files, setFiles] = useState([]);
  const [currentDocument, setCurrentDocument] = useState(null);
  const params = useMemo(
    () => BrowserUtils.getServiceParams(location.search),
    [location.search]
  );

  // getSetters
  const getSetLoan = useCallback(async () => {
    const loan = await ApiClient.getLoans(params.id);
    setLoan(loan);
    const pages = ArrayUtils.createIndex(loan.pages, "_id");
    loan.documents.forEach((doc, index) => {
      doc.index = index;
      doc.explorer = {
        type: "document",
        name: `(${index + 1}) ${doc.type}`,
      };
      doc.pages.forEach(
        (page) =>
          (page.src = BrowserUtils.getImageUrl(
            loan._id,
            pages[page._id].imageId
          ))
      );
    });
    dispatch({ type: "setRepo", repo: loan.documents });
    return loan;
  }, [params, dispatch]);

  const getSetJobs = useCallback(async () => {
    const jobs = await ApiClient.getJobsByLoan(params.id);
    setJobs(jobs);
    return jobs;
  }, [params]);

  const getSetFiles = useCallback(async () => {
    const files = await ApiClient.getFiles(params.id);
    setFiles(files);
    return files;
  }, [params]);

  const getSetData = useCallback(async () => {
    if (fetching.current) return;
    try {
      fetching.current = true;
      const [loan, jobs, files] = await Promise.all([
        getSetLoan(),
        getSetJobs(),
        getSetFiles(),
      ]);
      return [loan, jobs, files];
    } finally {
      fetching.current = false;
    }
  }, [getSetLoan, getSetJobs, getSetFiles]);

  // actions
  const deleteLoan = useCallback(async () => {
    try {
      loader.open();
      await ApiClient.deleteLoan(loan._id);
    } catch (e) {
      alert.open(<div>{e.toString()}</div>);
      return;
    } finally {
      loader.close();
    }
    navigate("/loans");
    await showCheckmark();
  }, [loan?._id, navigate, loader, showCheckmark, alert]);

  const filterJobsByType = (type) => {
    return jobs.filter((job) => job.type === type);
  };

  const uploadFiles = useCallback(() => {
    leftPopOut.open(
      DomUtils.getOrangeBlackHeader("Add", "Files"),
      <AddFiles loan={loan} />
    );
  }, [leftPopOut, loan]);

  const uploadLos = useCallback(() => {
    leftPopOut.open(
      DomUtils.getOrangeBlackHeader("Upload", "LOS"),
      <div>Not Implemented</div>
    );
  }, [leftPopOut]);

  // clicks
  const onDeleteLoan = useCallback(() => {
    confirm.open(
      <div>{`Are you sure you want to delete "${loan.name}"?`}</div>,
      deleteLoan
    );
  }, [confirm, loan?.name, deleteLoan]);

  const buttons = useMemo(() => {
    return [
      <LosIcon
        size={50}
        className="pad-10 button bg-blue"
        title="Upload LOS"
        onClick={uploadLos}
      />,
      <AddIcon
        size={50}
        className="pad-10 button bg-orange"
        title="Add Files"
        onClick={uploadFiles}
      />,
      <DeleteIcon
        size={50}
        className="pad-10 button bg-red"
        title="Delete Loan"
        onClick={onDeleteLoan}
      />,
    ];
  }, [uploadFiles, onDeleteLoan, uploadLos]);

  // useEffects
  // driver
  useEffect(() => {
    const driver = async () => {
      console.log("loan details driver");
      getSetData();
    };
    driver();
  }, [getSetData]);

  useEffect(() => {
    const id = setInterval(() => {
      getSetData();
    }, Vars.refreshInterval);
    return () => clearInterval(id);
  }, [getSetData]);

  useEffect(() => {
    dispatch({ type: "setButtons", buttons });
  }, [dispatch, buttons]);

  useEffect(() => {
    const document = state.item;
    if (!document || currentDocument?._id === document._id) return;
    setCurrentDocument(document);
    leftPopOut.open(
      DomUtils.getOrangeBlackHeader(
        document.type,
        `Pages: ${document.pages.length}`
      ),
      <Viewer images={document.pages} className={"w-half-screen"} />
    );
  }, [state.item, leftPopOut, currentDocument]);

  return (
    <div className="overflow-hidden flex-col gap-5 w-full h-full">
      <div className="bg-lg pad-5-10 flex gap-30">
        <StandardAttribute
          label="Total Files"
          data={files.filter((file) => file.metadata.isOriginal).length}
        />
        <StandardAttribute label="Total Pages" data={loan?.pages.length} />
        <StandardAttribute
          label="Total Documents"
          data={loan?.documents.length}
        />
        <StandardAttribute
          label="Documents to Review"
          data={
            loan?.documents.filter((doc) => doc.confidence < threshold).length
          }
          className={"button"}
          onClick={() =>
            dispatch({
              type: "setService",
              service: Services.classification,
            })
          }
        />
        <StandardAttribute
          label="Total Fields"
          data={loan?.documents.reduce(
            (sum, doc) => sum + doc.fields.length,
            0
          )}
        />
        <StandardAttribute
          label="Fields to Review"
          data={loan?.documents.reduce(
            (sum, doc) =>
              sum + doc.fields.filter((field) => field.confidence < 80).length,
            0
          )}
          className={"button"}
          onClick={() =>
            dispatch({
              type: "setService",
              service: Services.extraction,
            })
          }
        />
        <StandardAttribute
          label={"LOS Data"}
          data={loan?.losData ? "Uploaded" : "None"}
        />
      </div>
      <div className="overflow-hidden bg-lg h-full flex-col gap-10 pad-10 s-full">
        <JobDetails jobs={jobs} type="All Jobs" />
        <div className="flex space-between s-full gap-10">
          <div className="flex-col gap-10 s-full">
            <JobDetails
              jobs={filterJobsByType("split")}
              type="Pre-Processing"
            />
            <JobDetails jobs={filterJobsByType("recognize")} type="Recognize" />
            <JobDetails jobs={filterJobsByType("classify")} type="Classify" />
            <JobDetails jobs={filterJobsByType("extract")} type="Extract" />
          </div>
          <div className="flex-col gap-10 s-full">
            <JobDetails
              jobs={filterJobsByType("crossvalidate")}
              type="Cross Validate"
            />
            <JobDetails jobs={filterJobsByType("income")} type="Income" />
            <JobDetails jobs={filterJobsByType("audit")} type="Audit" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanDetails;
