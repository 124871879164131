const SelectService = ({ icons }) => {
  return (
    <div className="bg-lg h-full flex align-center justify-center">
      <div className="flex-col">
        <div className="center font-36 serif">Select a Service</div>
        <div className="flex space-evenly border-black br-5 pad-5">
          {icons.map((icon, idx) => (
            <div key={idx} className="button box-shadow-c br-5 bg-white">
              {icon}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectService;
