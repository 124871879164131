import FileRequest from "../utils/FileRequest";
import JsonRequest from "../utils/JsonRequest";

class ApiClient {
  static async getTenants() {
    return await JsonRequest.get(`/api/tenants`);
  }
  static async login(tenantId, username, password) {
    return await JsonRequest.post(`/api/auth/login`, {
      tenantId: tenantId,
      username: username,
      password: password,
    });
  }
  static async logout() {
    return await JsonRequest.post(`/api/auth/logout`, {});
  }
  static async changePassword(userId, credentials) {
    return await JsonRequest.put(`api/users/${userId}/password`, credentials);
  }
  static async refresh() {
    return await JsonRequest.post(`/api/auth/refresh`, {});
  }
  static async getLoans(loanId = "") {
    return await JsonRequest.get(`/api/loans/${loanId}`);
  }
  static async getBatches(loanId, batchId = "") {
    return await JsonRequest.get(`api/loans/${loanId}/batches/${batchId}`);
  }
  static async deleteLoan(loanId) {
    return await JsonRequest.delete(`api/loans/${loanId}`);
  }
  static async postLoan(loan) {
    return await await JsonRequest.post(`/api/loans`, loan);
  }
  static async putLoan(loan) {
    return await JsonRequest.put(`/api/loans/${loan._id}`, loan);
  }
  static async putDocument(loanId, doc) {
    return await JsonRequest.put(
      `/api/loans/${loanId}/documents/${doc._id}`,
      doc
    );
  }
  static async getAuditReports(loanId, reportId = "") {
    return await JsonRequest.get(
      `/api/loans/${loanId}/auditReports/${reportId}`
    );
  }
  static async getIncomeReport(loanId, reportId = "") {
    return await JsonRequest.get(
      `/api/loans/${loanId}/incomeReports/${reportId}`
    );
  }
  static async postLos(loanId, eFileType, fileContent) {
    return await JsonRequest.post(`/api/loans/${loanId}/los`, {
      eFileType: eFileType,
      fileContent: fileContent,
    });
  }
  static async addTag(loanId, tag) {
    return await JsonRequest.post(`/api/loans/${loanId}/tags`, {
      label: tag.label,
      content: tag.content,
    });
  }
  static async modifyTag(loanId, tag) {
    return await JsonRequest.put(`/api/loans/${loanId}/tags/${tag._id}`, tag);
  }
  static async getLoanFromBatchName(batchName) {
    const loans = await ApiClient.getLoans();
    const loan = loans.find((loan) => loan.name === batchName);
    return loan;
  }
  static async getJobs() {
    return await JsonRequest.get(`/api/jobs`);
  }
  static async getJobsByLoan(loanId) {
    const jobs = await JsonRequest.get(`/api/loans/${loanId}/jobs`);
    return jobs;
  }
  static async getFiles(loanId) {
    return await JsonRequest.get(`/api/loans/${loanId}/files`);
  }
  static async uploadFiles(loanId, files, workflowId) {
    const formData = new FormData();
    for (const file of files) {
      formData.append("files", file);
      formData.append("workflowId", workflowId);
    }
    return await FileRequest.post(`/api/loans/${loanId}/files`, formData);
  }
  static async getUser() {
    return await JsonRequest.get(`/api/users`);
  }
  static async getWorkflows() {
    return await JsonRequest.get(`/api/workflows`);
  }
  static getIncomeReportPdfUri(loanId, reportId) {
    return `/api/loans/${loanId}/incomeReports/${reportId}?format=pdf`;
  }
}

export default ApiClient;
