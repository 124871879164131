import { AppContext, ServiceContext } from "../../Contexts/Contexts";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useContext,
  useEffect,
  useReducer,
  cloneElement,
  Fragment,
  useMemo,
  useCallback,
} from "react";
import Services from "../../store/services";
import ApiClient from "../../clients/api.client";
import serviceReducer from "../../reducers/service.reducer";
import VerticalIconBar from "../../components/Bars/VerticalIconBar";
import BackToPageIcon from "../../components/Icons/BackToPageIcon";
import ServiceBanner from "../../components/Banners/ServiceBanner";
import Split from "react-split";
import Explorer from "../../components/Explorers/Explorer";
import SelectService from "./SelectService";
import DomUtils from "../../utils/dom.utils";
import BrowserUtils from "../../utils/browser.utils";

const LoanContainer = () => {
  const { alert, blocker } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const params = useMemo(
    () => BrowserUtils.getServiceParams(location.search),
    [location.search]
  );
  const [state, dispatch] = useReducer(serviceReducer, {
    service: params.service ? Services[params.service] : Services["details"],
    loan: null,
    item: null,
    repo: null,
    buttons: null,
    explorerFilters: {
      isHidden: () => false,
      buttons: [],
    },
  });

  // getSetters
  const getSetLoan = useCallback(async () => {
    const loan = await ApiClient.getLoans(params.id);
    dispatch({ type: "setLoan", loan: loan });
    return loan;
  }, [params.id]);

  const onServiceSelected = useCallback(
    (service) => {
      if (state.service === service) return;
      dispatch({ type: "setService", service: service });
    },
    [state.service]
  );

  const onItemSelected = (item) => {
    if (state.item === item) return;
    dispatch({ type: "setItem", item: item });
  };

  const getIcons = (currentService) => {
    const icons = [];
    const addIcon = (service) => {
      const bg = currentService?.id === service?.id ? "bg-green" : "";
      icons.push(
        <div className={`pad-10 flex align-center ${bg}`}>
          {cloneElement(service?.icon, {
            size: 30,
            className: "button",
            title: service?.label,
            onClick: () => onServiceSelected(service),
          })}
        </div>
      );
    };
    for (const serviceId in Services) {
      addIcon(Services[serviceId]);
    }
    return icons;
  };

  const onLeave = () => {
    navigate("/loans");
  };

  // driver
  useEffect(() => {
    const driver = async () => {
      try {
        getSetLoan();
      } catch (error) {
        if (params.isStandalone) {
          blocker.open(<div>{error.toString()}</div>);
        } else {
          alert.open(<div>{error.toString()}</div>, () => navigate("/"));
        }
      }
    };

    if (state.loan) return;
    console.log("ServiceContainer Driver");
    driver();
  }, [alert, navigate, state.loan, params, blocker, getSetLoan]);

  useEffect(() => {
    getSetLoan();
  }, [getSetLoan, state.service]);

  useEffect(() => {
    const blockTab = (e) => e.key === "Tab" && e.preventDefault();
    window.addEventListener("keydown", blockTab);
    return () => window.removeEventListener("keydown", blockTab);
  }, []);

  return (
    <ServiceContext.Provider value={{ state, dispatch }}>
      <div className="pad-10 h-full w-full">
        <div
          id="ServiceContainer"
          className="h-full w-full flex-col border-top-orange bg-white box-shadow gap-5 pad-5"
        >
          <div className="flex gap-5">
            <BackToPageIcon
              pageName="Dashboard"
              className="button pad-5"
              onClick={onLeave}
              disabled={params.isStandalone}
            />
            <ServiceBanner />
            {state.buttons && (
              <div className="flex gap-5">
                {state.buttons.map((button, idx) => (
                  <Fragment key={idx}>{button}</Fragment>
                ))}
              </div>
            )}
          </div>
          <div className="flex w-full h-full gap-5 overflow-hidden">
            <VerticalIconBar icons={getIcons(state.service)} />
            <Split
              className="flex w-full h-full overflow-hidden"
              sizes={[15, 85]}
              minSize={0}
              gutterSize={15}
              gutter={DomUtils.getGutter}
            >
              <Explorer
                repo={state.repo}
                name={state.service?.tableName}
                onClick={onItemSelected}
                index={state.item?.index}
                filters={state.explorerFilters}
              />
              <div className="overflow-hidden w-full h-full flex-col">
                {state.service?.component || (
                  <SelectService icons={getIcons(state.service)} />
                )}
              </div>
            </Split>
          </div>
        </div>
      </div>
    </ServiceContext.Provider>
  );
};

export default LoanContainer;
