import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EnhancedTableHead from "../EnhancedTableHead";
import { useState } from "react";
import LinkedCell from "../Cells/old.LinkedCell";
import { TableFooter } from "@mui/material";
import CompareUtils from "../../utils/compare.utils";
import DateTimeUtils from "../../utils/datetime.utils";
import ObjectUtils from "../../utils/object.utils";

const StandardTable = ({
  tableConfig,
  rowData,
  originalData,
  onRowClick,
  highlightRow,
  rowButton,
  containerOffset,
  showFooter,
  onIconClicks,
  type,
  initialOrder,
  className,
}) => {
  const [order, setOrder] = useState(initialOrder);
  const [orderBy, setOrderBy] = useState(tableConfig[0]?.id);
  const [orderType, setOrderType] = useState(tableConfig[0]?.type || "string");
  // const [selected, setSelected] = useState([]);
  // const [page, setPage] = useState(0);
  const page = 0;
  const showRowIndex = -1;
  // const [rowsPerPage, setRowsPerPage] = useState(1000);
  const rowsPerPage = 90000;

  const getRowBgColor = (idx) => {
    return idx % 2 ? "white" : "var(--light-gray)";
  };

  const highlight = (row) => {
    const usePointer = highlightRow ? "pointer" : "default";
    return highlightRow === row[orderBy] ? "selected" : usePointer;
  };

  const containerHeight = () => {
    return containerOffset ? `calc(100vh - ${containerOffset})` : "100%";
  };

  const handleRequestSort = (event, property, type) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setOrderType(type);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowData.length) : 0;

  return (
    <Box className={`standard-table-container ${className}`}>
      <Paper sx={{ width: "100%", height: "100%" }}>
        <TableContainer
          sx={{
            overflow: "auto",
            maxHeight: containerHeight(),
            borderBottom: "1px solid var(--light-gray)",
          }}
          className={"scrollbar"}
        >
          <Table aria-labelledby="tableTitle" size={"small"} stickyHeader>
            <EnhancedTableHead
              numSelected={0}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rowData.length}
              tableConfig={tableConfig}
              rowButton={rowButton}
            />
            <TableBody>
              {rowData
                .slice()
                .sort(CompareUtils.getComparator(order, orderBy, orderType))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, idx) => {
                  return (
                    <TableRow
                      className={highlight(row)}
                      style={{
                        backgroundColor: getRowBgColor(idx),
                      }}
                      hover
                      onClick={() => {
                        onRowClick(row);
                      }}
                      tabIndex={-1}
                      key={idx}
                    >
                      {tableConfig.map((header, idx) => {
                        let data;
                        if (header.type === "date") {
                          data = DateTimeUtils.format(row[header.id]);
                        } else if (header.function) {
                          try {
                            data = header.function(row[header.id]).toString();
                          } catch (error) {
                            throw new Error(
                              `Error with header: ${header.label}`
                            );
                          }
                        } else {
                          data = ObjectUtils.getNestedValue(row, header.id);
                        }
                        return (
                          <TableCell key={idx} className="table-cell">
                            <LinkedCell
                              row={row}
                              data={data}
                              config={header}
                              onClicks={onIconClicks}
                              tableType={type}
                            />
                          </TableCell>
                        );
                      })}
                      {rowButton != null && (
                        <TableCell className="edit-button">
                          {idx === showRowIndex
                            ? rowButton({ visibility: "visible" })
                            : rowButton({ visibility: "hidden" })}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {showFooter && (
              <TableFooter>
                <TableRow className="table-footer">
                  {tableConfig.map((columnConfig, idx) => {
                    let data = "";
                    if (columnConfig.footer) {
                      const columnData = rowData.map((row) => {
                        return row[columnConfig.id];
                      });
                      data = columnConfig
                        .footer(columnData, originalData.length)
                        .toString();
                    }
                    return (
                      <TableCell
                        key={idx}
                        sx={{
                          color: "black",
                          fontFamily: "var(--font-family-sans-serif)",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        {data}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[15]}
          component="div"
          count={rowData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton={true}
        /> */}
      </Paper>
    </Box>
  );
};

StandardTable.propTypes = {};

StandardTable.defaultProps = {
  highlightRow: null,
  rowButton: null,
  onRowClick: () => {},
  onIconClicks: {},
  originalData: [],
  tableConfig: [],
  initialOrder: "asc",
  className: "",
  rowData: [],
};

export default StandardTable;
