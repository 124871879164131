import PropTypes from "prop-types";
import { MdOutlineDragIndicator } from "react-icons/md";

const DragDocumentIcon = ({ size, className, onClick, title }) => {
  return (
    <MdOutlineDragIndicator
      className={`no-shrink  ${className}`}
      size={size}
      title={title}
      onClick={onClick}
    />
  );
};

DragDocumentIcon.defaultProps = {
  size: 30,
  className: "",
  onClick: () => {},
  title: "Drag Document",
};

DragDocumentIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

export default DragDocumentIcon;
