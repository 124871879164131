import { RiScissorsCutLine } from "react-icons/ri";
import PropTypes from "prop-types";

const ScissorsIcon = ({ onClick, size, sx, title, className }) => {
  return (
    <RiScissorsCutLine
      className={className}
      size={size}
      onClick={onClick}
      style={sx}
      title={title}
    />
  );
};

ScissorsIcon.defaultProps = {
  size: 15,
  onClick: () => {},
  sx: {},
  className: "",
};

ScissorsIcon.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
  sx: PropTypes.object,
  className: PropTypes.string,
};

export default ScissorsIcon;
