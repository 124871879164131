import blackIcon from "../../img/true/TRU-Icon-Black-Flat.svg";
import whiteIcon from "../../img/true/TRU-Icon-White-Flat.svg";

const TrueIcon = ({ color, size, className, sx }) => {
  const src = color === "black" ? blackIcon : whiteIcon;
  return (
    <img
      className={className}
      src={src}
      alt="TRUE Icon"
      width={`${size}px`}
      title="TRUE Icon"
      style={sx}
    />
  );
};

TrueIcon.defaultProps = {
  color: "black",
  size: 20,
  className: "",
  sx: {},
};

export default TrueIcon;
