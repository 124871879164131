import { ImArrowLeft } from "react-icons/im";

const ArrowLeftIcon = ({ size, className, title, onClick, disabled }) => {
  return (
    <ImArrowLeft
      className={className}
      size={size}
      title={title}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

ArrowLeftIcon.defaultProps = {
  className: "",
  title: "Arrow Left",
  onClick: () => {},
  disabled: false,
};

export default ArrowLeftIcon;
