import { IoChevronBackCircleOutline } from "react-icons/io5";
import { PropTypes } from "prop-types";
import { useState } from "react";

const ICON_SIZE = 50;

const BackToPageIcon = ({ onClick, pageName, disabled, className }) => {
  const [hover, setHover] = useState(false);
  const color = disabled ? "gray" : "var(--tertiary-bg-color-1)";
  return (
    <div className="relative" style={{ minWidth: `${ICON_SIZE}px` }}>
      <div
        className={`back-to-dashboard-icon-container bg-white ${
          hover ? "box-shadow absolute" : ""
        } br-5`}
        onMouseEnter={() => !disabled && setHover(true)}
        onMouseLeave={() => !disabled && setHover(false)}
      >
        <IoChevronBackCircleOutline
          style={{ color: color, pointerEvents: disabled ? "none" : "cursor" }}
          className={`${className}`}
          size={ICON_SIZE}
          onClick={onClick}
        />
        <div hidden={!hover} className="pad-6">
          <div style={{ color: color, fontSize: "small" }}>Back To</div>
          <div>{pageName}</div>
        </div>
      </div>
    </div>
  );
};

BackToPageIcon.defaultProps = {
  disabled: false,
  onClick: () => {},
  pageName: "<page>",
  className: "",
};

BackToPageIcon.propTypes = {
  onClick: PropTypes.func,
};

export default BackToPageIcon;
