import { useDrag } from "react-dnd";
import { useState } from "react";
import DragDocumentIcon from "../Icons/DragDocumentIcon";

const DocumentDragger = ({ _this, className, style }) => {
  const [hover, setHover] = useState(false);
  const [{ isDragging }, ref] = useDrag({
    type: _this[0].type,
    item: _this,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const scale = hover ? "scale(1.3)" : "scale(1)";
  const translate = style.transform ? style.transform : "";

  return (
    <div
      ref={ref}
      className={`grab flex-center ${className}`}
      title="Drag Document"
      style={{
        ...style,
        opacity: isDragging ? 0 : 1,
        transition: "transform 0.3s ease",
        transform: `${translate} ${scale}`,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <DragDocumentIcon size={20} className={"white r-90"} />
    </div>
  );
};

DocumentDragger.defaultProps = {
  style: {},
  className: "",
};

export default DocumentDragger;
