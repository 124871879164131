import { FaLock } from "react-icons/fa";

const LockIcon = ({ size, className, title, onClick }) => {
  return (
    <FaLock
      className={`${className} no-shrink`}
      size={size}
      title={title}
      onClick={onClick}
    />
  );
};

LockIcon.defaultProps = {
  className: "",
  title: "Locked",
  onClick: () => {},
};

export default LockIcon;
