import React from "react";
import { IoMdAlert } from "react-icons/io";

const AlertIcon = ({ onClick, size, sx, title, className }) => {
  return (
    <IoMdAlert
      className={`${className}`}
      onClick={onClick}
      size={size}
      style={sx}
      color="red"
      title={title}
    />
  );
};

AlertIcon.defaultProps = {
  size: 30,
  title: "Low Confidence",
  className: "",
};

export default AlertIcon;
