import { IoEye } from "react-icons/io5";
import PropTypes from "prop-types";

const EyeIcon = ({ onClick, size, sx, className, disabled }) => {
  return (
    <IoEye
      className={`icon ${className}`}
      size={size}
      onClick={onClick}
      style={sx}
      title="View"
      disabled={disabled}
    />
  );
};

EyeIcon.defaultProps = {
  size: 30,
  sx: {},
  disabled: false,
  onClick: () => {},
  className: "",
};

EyeIcon.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
  className: PropTypes.string,
  sx: PropTypes.object,
};

export default EyeIcon;
