import { useDrag } from "react-dnd";
import BrowseConstants from "../../store/browse.constants";
import { useState } from "react";

const Thumbnail = ({ _this, src, alt, style, className, onClick }) => {
  const [hover, setHover] = useState(false);
  const [{ isDragging }, ref] = useDrag({
    type: _this.type,
    item: [_this],
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const borderWidth = 1;
  const scale = hover ? "scale(1.1)" : "scale(1)";
  const translate = style.transform ? style.transform : "";

  return (
    <div
      ref={ref}
      className={className}
      style={{
        ...style,
        opacity: isDragging ? 0 : 1,
        border: `${borderWidth}px solid #ddd`,
        width: `${BrowseConstants.THUMBNAIL_WIDTH}px`,
        height: `${BrowseConstants.THUMBNAIL_HEIGHT}px`,
        transition: "transform 0.3s ease",
        transform: `${translate} ${scale}`,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <img
        src={src}
        alt={alt}
        style={{
          maxWidth: `${BrowseConstants.THUMBNAIL_WIDTH - borderWidth * 2}px`,
          maxHeight: `${BrowseConstants.THUMBNAIL_HEIGHT - borderWidth * 2}px`,
        }}
      />
    </div>
  );
};

Thumbnail.defaultProps = {
  style: {},
  className: "",
  onClick: () => {},
};

export default Thumbnail;
