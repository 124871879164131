const _default = {
  popup: {
    header: "",
    content: <></>,
    hide: true,
  },
  leftPopOut: {
    header: <></>,
    content: <></>,
    hide: true,
  },
  blocker: {
    content: <></>,
    hide: true,
  },
  alert: {
    content: <></>,
    hide: true,
    onAccept: () => {},
  },
  confirm: {
    content: <></>,
    hide: true,
    onAccept: () => {},
  },
  onUnload: {
    path: null,
    callback: null,
  },
  bannerIconConfig: {
    size: 48,
    sx: { padding: "8px" },
  },
  loader: {
    hide: true,
    content: null,
  },
};

export default _default;
