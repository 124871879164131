class DateTimeUtils {
  static getCurrentYear() {
    const today = new Date();
    return today.getFullYear();
  }
  static toUtc(str) {
    if (!str) return null;
    return str[str.length - 1] === "Z" ? new Date(str) : new Date(str + " UTC");
  }
  static format(date) {
    if (!date) return null;
    const options = {
      month: "numeric",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    };
    const timezone = sessionStorage.getItem("timezone");
    if (timezone && timezone !== "local") {
      options.timeZone = timezone;
    }
    return date.toLocaleString("en-US", options);
  }
  static toUtcAndFormat(str) {
    const date = this.toUtc(str);
    return this.format(date);
  }
}

export default DateTimeUtils;
