import { useCallback, useContext, useEffect } from "react";
import { AppContext } from "../../Contexts/Contexts";
import CandyButton from "../Buttons/CandyButton";

const Confirm = ({ confirmProps }) => {
  const { confirm } = useContext(AppContext);

  const hotkeys = useCallback(
    (e) => {
      if (e.ctrlKey) return;
      switch (e.key) {
        case "Enter":
          confirmProps.onAccept();
          confirm.close();
          break;
        case "Escape":
          confirm.close();
          break;
        default:
          break;
      }
    },
    [confirmProps, confirm]
  );

  useEffect(() => {
    window.addEventListener("keydown", hotkeys, false);
    return () => window.removeEventListener("keydown", hotkeys, false);
  }, [hotkeys]);

  return (
    <div className="confirm-container">
      <div style={{ textAlign: "center" }}>{confirmProps.content}</div>
      <div style={{ display: "flex", gap: "20px", margin: "auto" }}>
        <CandyButton
          className={"secondary-color-scheme"}
          element={<div style={{ margin: "0 10px" }}>OK</div>}
          onClick={() => {
            confirmProps.onAccept();
            confirm.close();
          }}
        />
        <CandyButton
          className={"secondary-color-scheme"}
          element={<div>Cancel</div>}
          onClick={() => confirm.close()}
        />
      </div>
    </div>
  );
};

export default Confirm;
