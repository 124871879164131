class BrowseConstants {
  static A4_RATIO = 1.4142;
  static LETTER_RATIO = 1.2941;
  static THUMBNAIL_WIDTH = 100;
  static THUMBNAIL_HEIGHT = Math.round(
    this.THUMBNAIL_WIDTH * this.LETTER_RATIO
  );
  static EXPOSED_DROPZONE_WIDTH = 10;
  static OVERLAY_DROPZONE_WIDTH = 25;
  static DROPZONE_WIDTH =
    this.EXPOSED_DROPZONE_WIDTH + this.OVERLAY_DROPZONE_WIDTH;
}

export default BrowseConstants;
