import { useState, useCallback, useEffect, useContext, useMemo } from "react";
import ClassificationPanel from "./Panels/classification.panel";
import { AppContext, ServiceContext } from "../../Contexts/Contexts";
import SaveIcon from "../../components/Icons/SaveIcon";
import ApiClient from "../../clients/api.client";
import BrowserUtils from "../../utils/browser.utils";
import ArrayUtils from "../../utils/array.utils";
import { copy } from "../../utils/general.utils";
import DomUtils from "../../utils/dom.utils";

const ClassificationService = () => {
  const { showCheckmark, loader, alert, workflows } = useContext(AppContext);
  const { dispatch, state } = useContext(ServiceContext);
  const loan = useMemo(() => state.loan, [state.loan]);
  const [documents, setDocuments] = useState([]);
  const { workflow, documentConfigs } = useMemo(() => {
    const workflow = workflows?.find((w) => w.name === "default");
    const documentConfigs =
      !workflow ?? ArrayUtils.createIndex(workflow.documentConfigs, "_id");
    return { workflow, documentConfigs };
  }, [workflows]);

  const getSetDocuments = useCallback(
    (loan) => {
      if (!loan || !workflow) return;
      const pages = ArrayUtils.createIndex(loan.pages, "_id");
      const documents = loan.documents;
      documents.forEach((doc, index) => {
        doc.threshold =
          documentConfigs[doc.type.toLowerCase()]?.threshold ??
          workflow.classification.defaultThreshold;
        doc.index = index;
        const warning = doc.confidence < doc.threshold ? "! " : "";
        doc.explorer = {
          type: "document",
          name: `(${index + 1}) ${warning} ${doc.type}`,
        };
        doc.pages.forEach(
          (page) =>
            (page.src = BrowserUtils.getImageUrl(
              loan._id,
              pages[page._id].imageId
            ))
        );
      });
      setDocuments(documents);
      return documents;
    },
    [documentConfigs, workflow]
  );

  // actions
  const save = useCallback(async () => {
    const docs = copy(documents);
    docs.forEach((doc) => {
      delete doc.threshold;
      delete doc.index;
      delete doc.explorer;
      doc.pages.forEach((page) => delete page.src);
    });
    loan.documents = docs;
    try {
      loader.open();
      await ApiClient.putLoan(loan);
      const updatedLoan = await ApiClient.getLoans(loan._id);
      dispatch({ type: "setLoan", updatedLoan });
    } catch (err) {
      alert.open(<div>{err.toString()}</div>);
    } finally {
      loader.close();
    }
    await showCheckmark();
  }, [documents, showCheckmark, loan, dispatch, loader, alert]);

  // onClicks
  const onSave = useCallback(() => {
    save();
  }, [save]);

  const Buttons = useMemo(
    () => [<SaveIcon size={50} className="pad-10 button" onClick={onSave} />],
    [onSave]
  );

  const hotkeys = useCallback(
    (e) => {
      if (DomUtils.hasPopup()) return;
      if (e.ctrlKey) {
        switch (e.key) {
          case "s":
            onSave();
            break;
          default:
            break;
        }
      }
    },
    [onSave]
  );

  useEffect(() => {
    const driver = () => {
      getSetDocuments(loan);
    };
    driver();
  }, [loan, getSetDocuments]);

  useEffect(() => {
    dispatch({ type: "setButtons", buttons: Buttons });
  }, [dispatch, Buttons]);

  useEffect(() => {
    dispatch({ type: "setRepo", repo: documents });
  }, [dispatch, documents]);

  useEffect(() => {
    window.addEventListener("keydown", hotkeys);
    return () => window.removeEventListener("keydown", hotkeys);
  }, [hotkeys]);

  return (
    <ClassificationPanel
      documents={documents}
      setDocuments={setDocuments}
      index={state.item?.index}
      onClick={(document) => {
        if (state.item !== document) {
          dispatch({ type: "setItem", item: document });
        }
      }}
    />
  );
};

export default ClassificationService;
