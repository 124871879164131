import React from "react";

const FallbackUI = ({ error, resetErrorBoundary }) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        padding: "50px 0",
        flexFlow: "column",
        textAlign: "center",
        gap: "10px",
      }}
    >
      <div>Something went wrong. Please try refreshing the screen.</div>
      <div>If the problem persists, contact your service provider.</div>
    </div>
  );
};

export default FallbackUI;
