import StandardAttribute from "../../components/Attributes/StandardAttribute";
import Block from "../../components/Blocks/Block";

const JobDetails = ({ jobs, type }) => {
  const filterByStatus = (status) => {
    return jobs.filter((job) => job.status === status);
  };
  const pending = filterByStatus("pending");
  const running = filterByStatus("running");
  const complete = filterByStatus("complete");
  const failed = filterByStatus("failed");

  const getBlocks = (jobs, color) => {
    const max = 15;
    const blocks = [];
    for (let i = 0; i < max; i++) {
      if (jobs.length > i) {
        blocks.push(<Block key={i} className={`bg-${color} border-black`} />);
      } else {
        blocks.push(<Block key={i} className="bg-white border-black" />);
      }
    }
    return blocks;
  };

  return (
    <div className="flex gap-30 border-black pad-5">
      <div className="flex-col gap-10">
        <div className="font-18">{`${type}`}</div>
        <div className="flex gap-20">
          <StandardAttribute label="Total" data={jobs.length} />
          <StandardAttribute label="Completed" data={complete.length} />
          <StandardAttribute label="Failed" data={failed.length} />
        </div>
      </div>
      <div className="flex-col gap-10">
        <StandardAttribute
          label="Pending"
          data={pending.length}
          children={
            <div className="flex gap-5">{getBlocks(pending, "blue")}</div>
          }
        />
        <StandardAttribute
          label="Running"
          data={running.length}
          children={
            <div className="flex gap-5">{getBlocks(running, "green")}</div>
          }
        />
      </div>
    </div>
  );
};

JobDetails.defaultProps = {
  jobs: [],
  type: null,
};

export default JobDetails;
