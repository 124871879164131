import TrueIcon from "../Icons/TrueIcon";
import DateTimeUtils from "../../utils/datetime.utils";

const version = document
  .querySelector('meta[name="version"]')
  .getAttribute("content");

const Footer = () => {
  const year = DateTimeUtils.getCurrentYear();

  return (
    <footer className="bg-black pad-0-5 font-12 flex space-between">
      <div className="flex gap-5">
        <TrueIcon size={15} color="white" />
        <div>{`Copyright ${year} TRUE`}</div>
      </div>
      <div className="flex align-center gap-5">
        <div
          className="button underline"
          onClick={() => alert("not implemented")}
        >
          Version {version.substring(0, version.lastIndexOf("."))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
