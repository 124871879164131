import StandardAttribute from "../../../components/Attributes/StandardAttribute";
import VersioningDocument from "../../../components/Panels/VersioningDocument";

const VersioningPanel = ({ documentType, onVersion }) => {
  return (
    <div className="s-full relative">
      <div
        className={`overflow-hidden flex-col gap-5 w-full h-full ${
          documentType ? "" : "block-events"
        }`}
      >
        <div className="bg-lg pad-5-10 flex gap-30">
          <StandardAttribute
            label="Final Count"
            data={
              documentType
                ? documentType.documents.filter(
                    (doc) => doc.version.toLowerCase() === "final"
                  ).length
                : 0
            }
          />
        </div>
        <div className="overflow-hidden bg-lg h-full">
          <div className="flex-wrap h-full gap-5 overflow scrollbar space-evenly pad-5">
            {documentType?.documents.map(
              (document, idx) =>
                !document.explorer.hidden && (
                  <VersioningDocument
                    key={idx}
                    document={document}
                    onVersion={onVersion}
                  />
                )
            )}
          </div>
        </div>
      </div>
      {!documentType && (
        <div className="absolute bg-blue white box-shadow pad-10 br-5 serif font-24 transform-center">
          Select a document type from the list.
        </div>
      )}
    </div>
  );
};

export default VersioningPanel;
