import Split from "react-split";
import StandardAttribute from "../../../components/Attributes/StandardAttribute";
import ExtractionUtils from "../../../utils/extraction.utils";
import Viewer from "../../../components/Viewers/Viewer";
import ExtractionRow from "../ExtractionComponents/ExtractionRow";
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useMemo } from "react";
import DomUtils from "../../../utils/dom.utils";

const ExtractionPanel = ({
  document,
  field,
  onFieldChange,
  onFocus,
  approveCell,
  onTableRowUpdate,
  rowRef,
  page,
}) => {
  const index = useMemo(() => {
    const pageNumber = field?.position?.page ?? page?.number ?? 1;
    return pageNumber - 1;
  }, [field, page]);
  const coordinates = field?.position ?? field?.coordinates;
  const dimensions = useMemo(() => {
    if (page) return { width: page.width, height: page.height };
    if (field?.position) return document?.pages[field.position.page - 1];
    return null;
  }, [page, field, document?.pages]);

  return (
    <div className="s-full relative">
      <div
        className={`s-full flex-col gap-5 ${document ? "" : "block-events"}`}
      >
        <div className="w-full bg-lg pad-5-10 flex gap-30">
          <StandardAttribute
            label="Total Fields"
            data={
              document
                ? ExtractionUtils.getFlattenedSources(document.rows).filter(
                    ExtractionUtils.getVisible
                  ).length
                : 0
            }
          />
          <StandardAttribute
            label="Fields to Review"
            data={
              document
                ? ExtractionUtils.getLowConfSources(document.rows).filter(
                    ExtractionUtils.getVisible
                  ).length
                : 0
            }
          />
        </div>
        <Split
          className="flex s-full bg-lg overflow-hidden"
          sizes={[40, 60]}
          minSize={0}
          gutterSize={15}
          gutter={DomUtils.getGutter}
        >
          <div className="pad-5">
            <Viewer
              images={document?.pages}
              index={index}
              coordinates={coordinates}
              dimensions={dimensions}
            />
          </div>
          <div className="s-full overflow-hidden pad-5">
            <div className="s-full overflow-hidden border-top-green pad-5 pad-b-10 bg-white box-shadow">
              <AutoSizer>
                {({ width, height }) => {
                  return (
                    <VariableSizeList
                      className="scrollbar bg-lg"
                      width={width}
                      height={height}
                      itemCount={document?.rows.length ?? 0}
                      itemSize={(index) =>
                        document
                          ? ExtractionUtils.getItemSize(document.rows[index])
                          : 0
                      }
                      itemData={{
                        rows: document?.rows,
                        onChange: onFieldChange,
                        onFocus: onFocus,
                        onTableChange: approveCell,
                        onTableRowUpdate: onTableRowUpdate,
                      }}
                      ref={rowRef}
                      children={ExtractionRow}
                    />
                  );
                }}
              </AutoSizer>
            </div>
          </div>
        </Split>
      </div>
      {!document && (
        <div className="absolute bg-blue white box-shadow pad-10 br-5 serif font-24 transform-center">
          Select a document from the list.
        </div>
      )}
    </div>
  );
};

export default ExtractionPanel;
