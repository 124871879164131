import { ImArrowRight } from "react-icons/im";

const ArrowRightIcon = ({ size, className, title, onClick, disabled }) => {
  return (
    <ImArrowRight
      className={className}
      size={size}
      title={title}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

ArrowRightIcon.defaultProps = {
  className: "",
  title: "Arrow Right",
  onClick: () => {},
  disabled: false,
};

export default ArrowRightIcon;
