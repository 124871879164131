import { useCallback, useContext, useEffect } from "react";
import { AppContext } from "../../Contexts/Contexts";
import CandyButton from "../Buttons/CandyButton";

const Alert = ({ alertProps }) => {
  const { alert } = useContext(AppContext);

  const hotkeys = useCallback(
    (e) => {
      switch (e.key) {
        case "Enter":
        case "Escape":
          alertProps.onAccept();
          alert.close();
          break;
        default:
          break;
      }
    },
    [alertProps, alert]
  );

  useEffect(() => {
    window.addEventListener("keydown", hotkeys, false);
    return () => window.removeEventListener("keydown", hotkeys, false);
  }, [hotkeys]);

  return (
    <div className="bg-white br-5 pad-10 gap-20 flex-col">
      <div style={{ textAlign: "center" }}>{alertProps.content}</div>
      <div className="flex-center">
        <CandyButton
          className="secondary-color-scheme"
          element={<div>Close</div>}
          onClick={() => {
            alertProps.onAccept();
            alert.close();
          }}
        />
      </div>
    </div>
  );
};

export default Alert;
