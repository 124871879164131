import { CgClose } from "react-icons/cg";
import { FaPlus } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
// import { useCallback } from "react";

export const FileInput = ({ files, addFiles, removeFile }) => {
  const { getRootProps, isDragActive } = useDropzone({
    onDrop: addFiles,
    noClick: true,
  });

  // const onDragOver = useCallback(() => {
  //   if (!isDragActive) return;
  //   const target = document.getElementsByClassName("files-uploaded-window");
  //   target[0].addEventListener("dragover", (e) => {
  //     e.dataTransfer.dropEffect = "move";
  //   });
  // }, [isDragActive]);
  return (
    <div className="flex-col h-full gap-5">
      <label
        htmlFor="files"
        className="flex-center bg-black green pad-10 gap-5 pointer"
      >
        <FaPlus size={14} />
        <div>Add Files</div>
      </label>
      <input
        hidden
        multiple
        type="file"
        id="files"
        name="files"
        onChange={(e) => addFiles(e.target.files)}
      />
      <div
        {...getRootProps()}
        className={`scroll-y border-lg h-full scrollbar ${
          isDragActive ? "active" : ""
        }`}
        // onDragOver={onDragOver}
      >
        {files.length === 0 && (
          <div className="pad-20 flex-center s-full">
            <div>Drag & Drop Files</div>
          </div>
        )}
        {files.map((file, idx) => {
          return (
            <div key={idx} className="files-window-row">
              <div className="files-window-text" title={file.name}>
                {file.name}
              </div>
              <div className="button">
                <CgClose
                  color="black"
                  size={12}
                  onClick={() => removeFile(file)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
