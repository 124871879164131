import { animated, useSpring } from "@react-spring/web";

const Slide = ({ isVisible, className, children }) => {
  const styles = useSpring({
    x: isVisible ? 0 : -90,
  });
  return (
    <animated.div className={className} style={styles}>
      {children}
    </animated.div>
  );
};

export default Slide;
