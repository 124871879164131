import { FaRotateLeft } from "react-icons/fa6";

const RotateLeftIcon = ({ size, className, title, onClick, disabled }) => {
  return (
    <FaRotateLeft
      className={className}
      size={size}
      title={title}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

RotateLeftIcon.defaultProps = {
  className: "",
  title: "Rotate Left",
  onClick: () => {},
  disabled: false,
};

export default RotateLeftIcon;
