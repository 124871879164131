import ScissorsIcon from "../Icons/ScissorsIcon";

const Split = ({ _this, style, onClick }) => {
  return (
    <button
      className="button flex-col justify-center align-center split"
      style={style}
      onClick={() => onClick(_this)}
      title="Split"
    >
      <div className="h-full" style={{ borderRight: "1px dashed black" }} />
      <ScissorsIcon title="Split" className={"rotate-90 no-shrink"} />
      <div className="h-full" style={{ borderRight: "1px dashed black" }} />
    </button>
  );
};

Split.defaultProps = {
  style: {},
};

export default Split;
