const documentTypes = [
  "203K Borrower Acknowledgment",
  "203K Borrower Identity Of Interest Certification",
  "203K Consultant Identity Of Interest Statement",
  "203k Consultants Certification",
  "203K Draw Request",
  "203K Homeowner Contractor Agreement",
  "203k Interest Rate Agreement",
  "203k Loan Program Notice",
  "203K Maximum Mortgage Worksheet",
  "203K Rehabilitation Agreement",
  "360Value Replacement Cost Valuation",
  "60 Day Asset Report",
  "60 Day Notice Letter",
  "Ability To Repay",
  "Ability To Repay Worksheet",
  "Academic Transcripts",
  "Account Closure Letter",
  "Accurint Report",
  "ACH Debit Authorization",
  "Acknowledgement of Closing Disclosure",
  "Acknowledgement of Loan Estimate",
  "Acknowledgement of Receipt CHARM Booklet",
  "Acknowledgement of Receipt of Home Loan Toolkit",
  "Acknowledgment Of Receipt",
  "Act Of Cash Sale",
  "Addendum To Loan Application",
  "Address Certification",
  "Advance Fee Agreement",
  "Affidavit",
  "Affidavit Of Commencement",
  "Affidavit Of Completion",
  "Affidavit Of Compliance",
  "Affidavit Of Consideration",
  "Affidavit Of Loan Estimate And Closing Disclosure",
  "Affidavit Of Non-Commencement",
  "Affiliated Business Arrangement Disclosure",
  "Affixation Affidavit",
  "Aggregate Adjustment Figure",
  "Agreement To Provide Financial Information",
  "Agreement To Provide Insurance",
  "AHP Retention Agreement Rider",
  "Alive and Well Certification",
  "Allonge To Note",
  "Amortization Schedule",
  "Anti Steering Disclosure",
  "Anti-Coercion Insurance Disclosure",
  "Anti-Discriminatory Loan Disclosure",
  "Applicable Law",
  "Applicant Credit Disclosure",
  "Application Disclosure",
  "Application Fee Disclosure",
  "Application For Contract Services",
  "Appraisal Acknowledgment",
  "Appraisal Disclosure",
  "Appraisal Enhanced Coverage Examination",
  "Appraisal Score",
  "Appraisal Waiver",
  "Appraiser Disclosure Statement",
  "Appraiser Qualifications",
  "APR And Finance Charge Summary",
  "ARM Disclosure",
  "Assignment",
  "Assumption Agreement",
  "Attorney Fee Letter",
  "Attorney Invoice",
  "Attorney Representation Notice",
  "Authorization For Disclosure By Prior Lien Holder",
  "Authorization to Complete Blank Spaces",
  "Authorization To Disburse Proceeds",
  "Auto Pay Instructions",
  "Automated Valuation Model",
  "Automated Valuation Model Disclosure",
  "Automatic Transfer Authorization",
  "Balloon Mortgage Disclosure",
  "Balloon Note Addendum",
  "Balloon Payment Disclosure",
  "Bank Statement",
  "Bankruptcy",
  "Billing Statement",
  "Birth Certificate",
  "Biweekly Rider",
  "Borrower Acknowledgement Of Federal Disclosures",
  "Borrower Authorization For Counseling",
  "Borrower Information for Funding and Disbursement",
  "Borrowers Authorization",
  "Borrowers Consent To Escrow Additional Funds",
  "Borrowers Preference Attorney And Insurance Agent",
  "Broadview Mortgage Loan Details",
  "Brokerage Agreement",
  "Business Entity Detail",
  "Buydown Agreement",
  "Buyer Advisory",
  "CABO and Lead Certificate",
  "CalHFA Borrower Affidavit",
  "CalHFA Commitment",
  "CalHFA DFA Disclosure",
  "CalHFA DFA Homebuyer Education Cert",
  "CalHFA Registration",
  "CalHFA State Reporting Disclosure",
  "California Finance Lenders Law",
  "California Impound Disclosure",
  "California Title Insurance Notice",
  "Candor Document Processed",
  "Candor Findings",
  "Candor Loan Income Verification Report",
  "Cash Flow Analysis",
  "CEMA",
  "Certificate From Originating Lenders Attorney",
  "Certificate Of Completion",
  "Certificate Of Compliance",
  "Certificate of Loans To One Borrower",
  "Certificate of Occupancy",
  "Certificate Of Parties Regarding Sales Contract",
  "Certificate Of Residence",
  "Certificate Of Resolution",
  "Certification For Individual Unit Financing",
  "Certified Mail Receipt",
  "Change Of Circumstance",
  "Check",
  "Choice Of Attorney",
  "Choice Of Insurance Notice",
  "Clear Capital Collateral Desktop Analysis (CDA)",
  "Closing Agent Certification",
  "Closing Attorneys Fee Certification",
  "Closing Certification",
  "Closing Disclosure",
  "Closing Disclosure Addendum",
  "Closing Instructions",
  "Closing Package Sheet",
  "Closing Protection Letter",
  "Collateral Checklist",
  "Collateral Protection Insurance Notice",
  "Collateral Tax Pledge Agreement",
  "Collateral Underwriter",
  "Commercially Reasonable Means or Mechanism",
  "Compliance Agreement",
  "Compliance Audit Sheet",
  "Compliance Package Sheet",
  "Compliance Report",
  "ComplianceEase Report",
  "Composite Mortgage Affidavit",
  "Conditional Commitment For Loan Guarantee",
  "Condo Information",
  "Condo Project Questionnaire",
  "Condominium Rider",
  "Conflict Of Interest Disclosure",
  "Construction Contract",
  "Construction Cost Breakdown",
  "Construction Loan Agreement",
  "Construction Loan Allonge To Note",
  "Construction Loan Disbursement Schedule",
  "Construction Rider",
  "Consumer Complaint Process Notice",
  "Consumer Credit Score Notice",
  "Consumer Explanation Letter",
  "Consumer Handbook On Adjustable Rate Mortgages",
  "Contractor HomeStyle Renovation Contract",
  "Conveyance Deed",
  "CoreLogic AVM",
  "Correction Agreement Power of Attorney",
  "Corrective Affidavit",
  "Correspondence",
  "Cosigner Notice",
  "Cover Letter",
  "COVID-19 Attestation",
  "COVID-19 Temporary Flexibilities",
  "Credit Application",
  "Credit Application Addendum",
  "Credit Application Broker Addendum",
  "Credit Application Sales Information",
  "Credit Card Statement",
  "Credit Demographic Information",
  "Credit Monitoring Invoice",
  "Credit Report",
  "Credit Report Authorization",
  "Credit Report Cover Page",
  "Credit Report Invoice",
  "Credit Report Supplement",
  "Credit Union Membership Application",
  "Customer Satisfaction Survey",
  "Data Collection For Government Monitoring",
  "Data Entry Proof Sheet",
  "Data Proof Sheet Fees",
  "DD Form 214",
  "Death Certificate",
  "Declination Letter",
  "Definitions Of TIL Terms",
  "Delivery Fee Authorization",
  "Description Of Underwriting Criteria",
  "Disbursement Worksheet",
  "Disclosure Notices",
  "Disclosure Of Business Relationships",
  "Disclosure Of Loan Provisions",
  "Disclosure Of Multiple Roles",
  "Disclosure Of No Interim Financing",
  "Disclosure Regarding Dual Agency",
  "Disclosure Requirements",
  "Disclosure Statement About MERS",
  "Disclosure Tracking Details",
  "Discount Fee Disclosure",
  "Divorce Agreement",
  "Document Audit Log",
  "Document Checklist",
  "Document Correction Agreement",
  "Drive Report",
  "Drivers License",
  "DU EarlyCheck",
  "Dual Capacity Disclosure",
  "DU-Desktop Underwriting",
  "Earnest Money Deposit",
  "Electronic Disclosure And Consent Agreement",
  "Electronic Mailing Affidavit",
  "Elevation Certificate",
  "Email Authorization Form",
  "Employment Letter",
  "Equal Credit Opportunity Act",
  "Errors And Omissions Insurance",
  "Escrow Account Option Notice",
  "Escrow Account Statement",
  "Escrow Agency Disbursement",
  "Escrow Instructions",
  "Escrow Waiver",
  "eSign Certificate",
  "Evidence Of Joint Application",
  "Exemption Valuation Notice",
  "Fair Credit Reporting Act",
  "Fair Credit Reporting Act Letter",
  "Fair Lending Notice",
  "Fair Market Value Of Homestead Property",
  "Fee Itemization",
  "FHA - Refinance Authorization",
  "FHA Connection - Appraisal Logging Results",
  "FHA Connection - CAIVRS Authorization",
  "FHA Connection - Case Number Assignment",
  "FHA Connection - Case Query",
  "FHA Connection - Mortgage Credit Reject",
  "FHA Connection - Other",
  "FHA Rehabilitation Loan Rider",
  "FHA VA Borrower Paid Charges Disclosure",
  "FHA-VA - Amendatory Clause",
  "FHA-VA - Buydown Agreement",
  "FHA-VA - New Construction Certification",
  "FHA-VA - Notice to Applicants",
  "Final Bills Paid Affidavit And Waiver Of Lien",
  "Financing Agreement",
  "First Lien Letter",
  "First Payment Letter",
  "Float Lock Agreement",
  "Flood Disaster Protection Act Of 1973",
  "Flood Disclosure",
  "Flood Hazard Determination",
  "Flood Insurance Authorization",
  "Floor Plan Sketch",
  "FMCC Form 400",
  "Forbearance Agreement",
  "Form 1003 Uniform Residential Loan Application",
  "Form 1004 Uniform Residential Appraisal",
  "Form 1004C Manufactured Home Appraisal",
  "Form 1004D Appraisal Update And Completion Report",
  "Form 1008 Addendum",
  "Form 1008 Uniform Underwriting and Transmittal Summary",
  "Form 1025 Small Residential Income Property Appraisal",
  "Form 1040",
  "Form 1040 - Other Schedule",
  "Form 1040 - Schedule 1",
  "Form 1040 - Schedule B",
  "Form 1040 - Schedule C",
  "Form 1040 - Schedule C-EZ",
  "Form 1040 - Schedule D",
  "Form 1040 - Schedule E",
  "Form 1040 - Schedule F",
  "Form 1040 - SR",
  "Form 1040 - Variant",
  "Form 1041",
  "Form 1065",
  "Form 1065 Schedule",
  "Form 1073 Individual Condominium Unit Appraisal",
  "Form 1075 Exterior Individual Condo Appraisal",
  "Form 1095",
  "Form 1098",
  "Form 1099",
  "Form 1120",
  "Form 1120 Schedule",
  "Form 1120S",
  "Form 1120S Schedule",
  "Form 2055 Exterior Residential Appraisal",
  "Form 2090 Individual Cooperative Interest Appraisal",
  "Form 2106",
  "Form 4506C",
  "Form 4506T",
  "Form 706",
  "Form 990",
  "Form SSA-1099",
  "Form T-64 - Texas Disclosure",
  "FraudGuard Report",
  "Funding Conditions For Investor Sheet",
  "Funding Docs Sheet",
  "Funding Fee Notice",
  "Funding Transmittal",
  "FundingShield Certification",
  "Gap Indemnity Agreement",
  "Gift Letter",
  "Good Faith Estimate",
  "Good Faith Estimate Provider Relationship",
  "Grade Sheet",
  "Grade Sheet Supporting Document",
  "Ground Lease Renewals",
  "Guaranteed Loan Closing Report",
  "GUS",
  "Hardship",
  "Hazard Insurance",
  "Hazard Insurance Authorization And Disclosure",
  "Hazard Insurance Information",
  "HECM Closing Costs And Liens Schedule",
  "HECM Counseling Waiver Qualification",
  "HECM Financial Assessment Worksheet",
  "HECM Notice To Borrower",
  "High Loan To Value Disclosure",
  "HOA Account History",
  "HOA Information and Acknowledgement",
  "HOEPA HMDA Required Information",
  "Hold Harmless Agreement",
  "Hold Letter",
  "Home Affordable Refinance",
  "Home Equity Line Of Credit",
  "Home Loan Application",
  "Homebuyer Education Certificate",
  "Homeowners Affidavit",
  "Homestead Affidavit",
  "Housing Counseling Cert Of Completion",
  "HSR Consumer Tips",
  "HSR Maximum Mortgage Worksheet",
  "HSR Payment Disclosure",
  "HUD - Appraised Value Disclosure",
  "HUD Worksheet 2 - Cash Out Non Streamline",
  "HUD Worksheet 3 - Streamline Refinance Without App",
  "HUD Worksheet 4 - Streamline Refinance With App",
  "HUD Worksheet 5 - Building On Own Land",
  "HUD-1 - Settlement Statement",
  "HUD-11708",
  "HUD-50012 - HECM Extension",
  "HUD-54111 Request For Insurance Endorsement",
  "HUD-54114 - Analysis Of Appraisal Report",
  "HUD-56146 - Repayment Agreement",
  "HUD-91322.3 - Statement of Appraised Value",
  "HUD-92051 - Compliance Inspection Report",
  "HUD-92070 - SCRA Notice Disclosure",
  "HUD-92080 - Mortgage Record Change",
  "HUD-92300 - Mortgagees Assurance Of Completion",
  "HUD-92541 - Builders Certification",
  "HUD-92544 - Warranty Of Completion",
  "HUD-92561 - Hotel Transient Use",
  "HUD-92564-CN - Get A Home Inspection",
  "HUD-92800.5B - Conditional Commitment",
  "HUD-92900-A - Addendum To URLA",
  "HUD-92900-B - Important Notice To Homebuyers",
  "HUD-92900-LT - Loan Transmittal",
  "HUD-92900-PUR - Mortgage Credit Analysis Worksheet",
  "HUD-92900-WS - Mortgage Credit Analysis Worksheet",
  "HUD-92901 - HECM Anti-Churning Disclosure",
  "HUD-92918 - FHA Refinance of Borrowers Certification",
  "HUD-93101 - Recertification Of Family Income",
  "HUD-9519-A - Property Inspection Report",
  "HUD-NPMA-99-A - Termite Protection Guarantee",
  "HUD-NPMA-99-B - Termite Service Record",
  "I-797 - Notice Of Action",
  "ID",
  "ID Cross Check",
  "IDS Fullfillment System Certificate",
  "Illinois Anti-Predatory Lending Certificate",
  "Importance of Home Inspections",
  "Impound Authorization",
  "Income Calculation Worksheet",
  "Incomplete Letter",
  "Information About Brokerage Services",
  "Informed Consumer Choice Disclosure Notice",
  "Initial Escrow Disclosure",
  "Inspection Waiver",
  "Insurance Endorsement Change Request",
  "Insurance Requirements On All Loans",
  "Insured Authorization To Release Information",
  "Intent To Proceed",
  "Intentionally Blank",
  "Inter Vivos Revocable Trust Rider",
  "Interest Rate Rider",
  "Investment Account",
  "Invoice",
  "IRS Disclosure",
  "Itemization Of Amount Financed",
  "Itemization Of Credit",
  "LCA - Feedback Certificate",
  "Legal Description",
  "Lenders Closing Affidavit",
  "Lenders Disbursement Statement Authorization",
  "Letter Of Explanation",
  "Level One Authentication",
  "Liability for Shortages Acknowledgment",
  "Licensee Information",
  "Licensing Disclosure",
  "Lien Waiver",
  "Limited Denial Participation List",
  "Limited Property Report",
  "Loan Applicants Attestation",
  "Loan Approval",
  "Loan Closing Acknowledgment",
  "Loan Detail Report",
  "Loan Disbursement Acknowledgment",
  "Loan Disbursement Instructions",
  "Loan Estimate",
  "Loan Fraud Statement",
  "Loan Modification",
  "Loan Modification Form",
  "Loan Options Disclosure",
  "Loan Quality Advisor Report",
  "Loan Request Consumer Information Letter",
  "Loan Submission Form",
  "Loan Transmittal Memorandum",
  "LoanSafe Fraud Manager",
  "Lost Note Affidavit",
  "LP-BOA",
  "LP-Credit Infiles",
  "LP-Document Checklist",
  "LP-Loan Prospector",
  "LP-Property Valuation Disclosure Form",
  "Manufactured Home Application",
  "Manufactured Housing Rider",
  "Marital Status Form",
  "Mavent Review",
  "MERS Registry Rider",
  "MI Certificate",
  "MI Disclosure",
  "MI Prequalified Approval",
  "MI Rate Quote",
  "Min Summary",
  "Mineral Rights Acknowledgment",
  "Misc Docs Sheet",
  "Miscellaneous",
  "Mortgage Application Disclosure",
  "Mortgage Broker Fee Agreement",
  "Mortgage Fraud Notice",
  "Mortgage Insurance Application",
  "Mortgage Loan Origination Agreement",
  "Mortgage Statement",
  "Net Funding Worksheet",
  "Net Proceeds Authorization",
  "NJ Application Disclosure Form",
  "NMLS Consumer Access",
  "NMLS Verification",
  "No HUD Warranty",
  "Non Owner Occupancy Rider",
  "Notary Acknowledgement",
  "Notary Attention Required",
  "Note",
  "Note Addendum",
  "Note Coversheet",
  "Notice Concerning Escrow Account",
  "Notice Of Adverse Action",
  "Notice Of Change In Loan Terms",
  "Notice Of Flood Hazard Acknowledgment",
  "Notice Of Furnishing Negative Information",
  "Notice Of Homeownership Counseling",
  "Notice Of Increase In Property Taxes",
  "Notice Of Legal Representation",
  "Notice Of No Oral Agreements",
  "Notice Of Nonrefundability Of Loan Fees",
  "Notice Of Oral Agreements",
  "Notice Of Penalties For False Statements",
  "Notice Of Required Flood Insurance Determination",
  "Notice Of Right To Cancel",
  "Notice Of Right To Discontinue Escrow",
  "Notice Of Separate Credit Account",
  "Notice Of Special Flood Hazard And Availability",
  "Notice Of Taxes On New Construction",
  "Notice Regarding Copies Of Documents",
  "Notice Regarding Loan Payment",
  "Notice To Borrower In Special Flood Hazard Area",
  "Notice To Borrower Not In Special Flood Hazard Area",
  "Notice To Borrower Of Property Taxes",
  "Notice to Closing Agent",
  "Notice To Homeowner",
  "Notice To Loan Applicants",
  "Notice To Purchaser",
  "Notice To Veterans",
  "Notification Of Withholding Tax Requirement",
  "Notification Regarding Tax Bills",
  "Obligation of Debts Affidavit",
  "Occupancy Affidavit",
  "Occupancy Agreement",
  "Occupancy and Financial Statement",
  "OFAC Report",
  "Ohio Closing Disclosure",
  "Ohio Disclosure Acknowledgement of Receipt",
  "One To Four Family Rider",
  "Operating Income Statement",
  "Other Automated Underwriting Decision",
  "Owners Affidavit",
  "Partnership Agreement",
  "Passport",
  "Patriot Act Borrower Identification",
  "Patriot Act Customer ID Verification",
  "Patriot Act Information Form",
  "Payment Coupon",
  "Payment Forms",
  "Payment Schedule",
  "Payoff Statement",
  "Paystub",
  "Pennsylvania Act 91 Notice",
  "Per Diem Interest Disclosure",
  "Permanent Resident",
  "Power Of Attorney",
  "Power of Attorney Instructions",
  "Pre Application Disclosure And Fee Agreement",
  "PredProtect Worksheet",
  "Preferred Payment Plan Authorization",
  "Pre-Op Credit Report Findings",
  "Prepayment Rider",
  "Prior History",
  "Privacy Policy",
  "Privacy Policy Fact Sheet",
  "Processor Certification",
  "Profit Loss and Balance Statement",
  "Prohibited Acts Disclosure Home Loans",
  "Proof of Identity",
  "Property Acceptance",
  "Property Image",
  "Property Listing",
  "Property Preservation Inspection",
  "Property Tax Status",
  "PUD Rider",
  "PUD Warranty",
  "Purchase Advice",
  "Purchase Agreement",
  "QM Findings",
  "Quality Control Release",
  "Rate Lock Agreement",
  "Recording Coversheet",
  "Red Flag",
  "Refinance Certificate",
  "Regulatory Agency Disclosure",
  "Renewal Addendum To Modification Agreement",
  "Renewal and Extension Exhibit",
  "Renovation Loan Agreement",
  "Renovation Rider",
  "Rental Agreement",
  "Request For Mortgage Assistance",
  "Request for Single Family Housing Loan Guarantee",
  "Request For Verification Of Employment",
  "Request to Payoff and Close",
  "Requirements Regarding Insurance",
  "Reserve Account Disclosure",
  "Residential Construction Loan Addendum",
  "Residential Construction Loan Contractors Affidavit",
  "Residential Real Property Affidavit",
  "Return Fax Cover Sheet",
  "Review Checklist",
  "Right to Declare Homestead Protection",
  "Risk Based Pricing Notice",
  "SAM Search Results",
  "Satisfaction Of Security Instrument",
  "Schedule K-1 - Form 1041",
  "Schedule K-1 - Form 1065",
  "Schedule K-1 - Form 1120S",
  "Schedule K-1 - Form 8865",
  "Schedule K-1 Supplement",
  "Schedule Of Real Estate Owned",
  "Second Home Rider",
  "Section 225 Tax Law",
  "Secure Insight Agent Risk Report",
  "Security Instrument",
  "Sellers Closing Disclosure",
  "Service Providers List",
  "Servicing Disclosure Statement",
  "Servicing Fees Disclosure",
  "Servicing Transfer Statement",
  "Settlement Certification",
  "Settlement Statement",
  "Settlement Statement Certification",
  "Shipping Label",
  "Shopping For Your Home Loan",
  "Signature Name Affidavit",
  "Signature Page",
  "Signing Requirements",
  "Smoke Alarm Affidavit",
  "Social Security Card",
  "Social Security Letter",
  "Social Security Number Certification",
  "Solar Documents",
  "SSA-89 - Authorization To Release SSN Verification",
  "Standard Flood Hazard Determination",
  "Standard Flood Insurance Policy",
  "Statement Of Credit Denial",
  "Statement Of Information",
  "Submission Summary Report",
  "Subordination Agreement",
  "Subsidy Agreement",
  "Summary Of Your Rights",
  "Supplemental Consumer Information Form",
  "Survey Affidavit",
  "Tangible Net Benefit",
  "Tax Authorization",
  "Tax Certification",
  "Tax Indemnity",
  "Tax Information Sheet",
  "Tax Return",
  "Tax Statement",
  "Tax Transcript",
  "Taxpayer Consent Form",
  "Termite Report",
  "Texas 12 Day Letter",
  "Texas Acknowledgement Of Repayment Of Debt",
  "Texas Home Equity Affidavit And Agreement",
  "Texas Mortgage Banker Disclosure",
  "Texas Refinance to Non-Home Equity",
  "Title Commitment",
  "Title Endorsement",
  "Title Exceptions Receipt",
  "Title Invoice",
  "Title Policy",
  "TRID 3rd Party Authorization",
  "Trust Agreement",
  "Truth In Lending",
  "UCC Financing Statement",
  "UCD Findings Report",
  "Underwriting Package Sheet",
  "Undisclosed Debt",
  "Universal Loan Identifier Submission Form",
  "Unlimited Guaranty",
  "Up Front Mortgage Insurance Premium",
  "URLA - Additional Borrower",
  "URLA - Borrower Information",
  "URLA - Continuation Sheet",
  "URLA - Demographic Information",
  "URLA - Lender Loan Information",
  "URLA - Unmarried Addendum",
  "USDA Loan Note Guarantee",
  "USDA Notice to Applicant Household Income",
  "USDA-RHS Occupancy Rider",
  "VA - Affirmative Marketing Certification",
  "VA - Amendment To Contract",
  "VA - Benefit Indebtedness Certification",
  "VA - Cash Out Home Equity Disclosure",
  "VA - Cash Out Net Tangible Benefit",
  "VA - Cash Out Refinance Disclosure",
  "VA - Certificate of Eligibility",
  "VA - Discount Disclosure Statement",
  "VA - Entitlement Worksheet",
  "VA - Equal Opportunity Lender Certification",
  "VA - Home Loan Guaranty Benefits",
  "VA - Lender Certification",
  "VA - Lenders Notice of Value",
  "VA - Nearest Living Relative",
  "VA - Not Inspected Acknowledgement",
  "VA - Origination Statement",
  "VA - Questionnaire",
  "VA - Rate Reduction Certification",
  "VA - Rate Reduction Refinance Agreement",
  "VA - Supplement Application",
  "VA - Veterans Statement And Lenders Certification",
  "VA 26-0285 - Transmittal List",
  "VA 26-0286 - Loan Summary Sheet",
  "VA 26-0503 - Federal Collection Policy Notice",
  "VA 26-0551 - Debt Questionnaire",
  "VA 26-0592 - Counseling Checklist for Military Homebuyers",
  "VA 26-1805 - Request For Determination Of Value",
  "VA 26-1820 - Certification Of Loan Disbursement",
  "VA 26-1839 - Compliance Inspection Report",
  "VA 26-1843 - Certificate Of Reasonable Value",
  "VA 26-1880 - Request For Certificate Of Eligibility",
  "VA 26-1899 - Loan Guaranty Certificate",
  "VA 26-6393 - Loan Analysis",
  "VA 26-8261a - Request For Certificate of Veteran Status",
  "VA 26-8923 - IRRRL Worksheet",
  "VA 26-8937 - Verification of VA Benefits",
  "VA 26-8978 - Rights Of VA Loan Borrowers",
  "VA Child Care Certification",
  "VA Rider",
  "Variable Rate Loan Disclosure",
  "Verbal Verification Of Employment",
  "Verification Of Address",
  "Verification Of Benefits",
  "Verification Of Deposit",
  "Verification Of Employer",
  "Verification of HOA Dues",
  "Verification Of Income",
  "Verification Of Mortgage",
  "Verification Of SSN",
  "Voluntary Escrow Account Payment",
  "W-2",
  "W-4",
  "W-7",
  "W-8",
  "W-9",
  "Waiting Period Disclosure",
  "Waiver of 72 Hour Advance Presentation Requirement",
  "Waiver Of Borrowers Rights Rider",
  "Waiver Of Subcontractors and Suppliers",
  "Warehouse Check Sheet",
  "Wire Instructions",
  "Wire Transfer Authorization",
  "Wisconsin Tax Escrow Notice",
  "Wisconsin Tax Escrow Option Election",
  "Work Number Verification",
  "Writ Of Fieri Facias",
  "Written Verification Of Employment",
  "WSHFC Award Letter",
  "WSHFC Commitment",
  "Your Home Loan Toolkit",
  "ZIP Acknowledgement",
];

export default documentTypes;
