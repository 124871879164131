import { useState, useContext, useCallback, useEffect } from "react";
import { AppContext } from "../../Contexts/Contexts";
import ApiClient from "../../clients/api.client";
import StandardAttribute from "../../components/Attributes/StandardAttribute";
import SubmitInput from "../../components/Inputs/SubmitInput";
import textFieldProps from "../../store/textfield.props";
import { TextField } from "@mui/material";
import StandardToaster from "../../components/Toasters/StandardToaster";
import { copy } from "../../utils/general.utils";
import StringUtils from "../../utils/string.utils";

const defaultCredentials = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const MIN_LENGTH = 8;

const Profile = () => {
  const { user, showCheckmark, leftPopOut, appsettings } =
    useContext(AppContext);
  const [credentials, setCredentials] = useState(defaultCredentials);
  const [toasterMsg, setToasterMsg] = useState("");

  const validatePassword = useCallback(() => {
    if (credentials.newPassword !== credentials.confirmPassword) {
      return "Passwords Do Not Match";
    }
    if (credentials.newPassword.length < MIN_LENGTH) {
      return `Password Must Be At Least ${MIN_LENGTH} Characters`;
    }
    return "valid";
  }, [credentials]);

  const onSave = async (e) => {
    e.preventDefault();
    const res = await ApiClient.changePassword(credentials);
    if (res.status === 401) {
      setToasterMsg("Incorrect Old Password");
      return;
    }
    if (res.status >= 500) {
      setToasterMsg("An Internal Server Error Occurred");
      return;
    }
    if (appsettings.useVerifai) {
      const res = await ApiClient.changePassword(credentials);
      if (res.status === 401) {
        setToasterMsg("Incorrect Old Password");
        return;
      }
      if (res.status >= 500) {
        setToasterMsg("An Internal Server Error Occurred");
        return;
      }
    }
    leftPopOut.close();
    showCheckmark();
  };

  const onChange = (key, value) => {
    credentials[key] = value;
    setCredentials(copy(credentials));
  };

  useEffect(() => {
    if (user.IsSsoUser) {
      setToasterMsg("Password Change Not Available For SSO Users");
      return;
    }
    const validate = validatePassword();
    if (validate === "valid") {
      setToasterMsg("");
    } else {
      setToasterMsg(validate);
    }
  }, [credentials, validatePassword, user.IsSsoUser]);

  return (
    <div className="standard-content">
      <div className="flex-col gap-30">
        <div className="flex-col gap-10">
          <label className="standard-label">General Information</label>
          <StandardAttribute label="Username" data={user.username} />
          <StandardAttribute
            label="Roles"
            data={user.roles
              .map((role) => StringUtils.capitalize(role))
              .join(", ")}
          />
          <StandardAttribute
            label="Identity Provider"
            data={user.identityProvider ?? " Not Implemented"}
          />
        </div>
        <form onSubmit={onSave} className="flex-col gap-10">
          <label className="standard-label">Change Password</label>
          <div className="flex-col gap-10 w-300">
            <TextField
              disabled={user.IsSsoUser}
              type="password"
              variant={user.IsSsoUser ? "filled" : "outlined"}
              label={"Old Password"}
              value={credentials.oldPassword}
              size="small"
              onChange={(e) => onChange("oldPassword", e.target.value)}
              InputLabelProps={{ style: { color: "black" }, shrink: true }}
              inputProps={{
                style: textFieldProps,
              }}
            />
            <TextField
              disabled={user.IsSsoUser}
              type="password"
              variant={user.IsSsoUser ? "filled" : "outlined"}
              label={"New Password"}
              value={credentials.newPassword}
              size="small"
              onChange={(e) => onChange("newPassword", e.target.value)}
              InputLabelProps={{ style: { color: "black" }, shrink: true }}
              inputProps={{
                style: textFieldProps,
              }}
            />
            <TextField
              disabled={user.IsSsoUser}
              type="password"
              variant={user.IsSsoUser ? "filled" : "outlined"}
              label={"Confirm New Password"}
              value={credentials.confirmPassword}
              size="small"
              onChange={(e) => onChange("confirmPassword", e.target.value)}
              InputLabelProps={{ style: { color: "black" }, shrink: true }}
              inputProps={{
                style: textFieldProps,
              }}
            />
          </div>
          <StandardToaster text={toasterMsg} />
          <div className="flex-end">
            <SubmitInput
              value="Save"
              disabled={user.IsSsoUser || validatePassword() !== "valid"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
