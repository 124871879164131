import { TextField, Autocomplete } from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import ApiClient from "../../../clients/api.client";
import { AppContext } from "../../../Contexts/Contexts";
// import LoanTag from "../../../models/Loan/LoanTag";
import SubmitInput from "../../../components/Inputs/SubmitInput";
import { useNavigate } from "react-router-dom";
import { copy } from "../../../utils/general.utils";
import StringUtils from "../../../utils/string.utils";

const emptyNameError = "No Name Supplied";
const noWorkflowError = "No Workflow Selected";
const nameExistsError = "Loan name already exists.";

const AddLoan = ({ loans }) => {
  const navigate = useNavigate();
  const { loader, showCheckmark, alert, leftPopOut, workflows } =
    useContext(AppContext);
  const [loan, setLoan] = useState({ name: "" });
  const [workflow, setWorkflow] = useState(
    workflows.find((c) => c.name === "default")
  );
  // const [tag, setTag] = useState(new LoanTag("", ""));
  const [toaster, setToaster] = useState("");

  const loanNames = useMemo(() => {
    const loanNames = new Set();
    for (const loan of loans) {
      loanNames.add(loan.name.toLowerCase());
    }
    return loanNames;
  }, [loans]);

  // actions
  const submit = async (loan) => {
    loader.open();
    let loanId;
    try {
      loanId = (await ApiClient.postLoan(loan))._id;
      // await ApiClient.addTag(loanId, tag);
    } catch (error) {
      alert.open(<p>{error.message}</p>);
      return;
    } finally {
      loader.close();
    }
    leftPopOut.close();
    await showCheckmark();
    navigate(`/loan?id=${loanId}`);
  };

  // clicks
  const onSubmit = async (e) => {
    e.preventDefault();
    setToaster("");
    if (loan.name === "") {
      setToaster(emptyNameError);
      return;
    }
    if (!workflow) {
      setToaster(noWorkflowError);
      return;
    }
    if (loanNames.has(loan.name.toLowerCase())) {
      setToaster(nameExistsError);
      return;
    }
    loan.workflowId = workflow._id;
    await submit(loan);
  };

  return (
    <div className="standard-content">
      <form className="flex-col gap-5" onSubmit={onSubmit}>
        <div className="flex-col gap-20">
          <TextField
            style={{ minWidth: "300px" }}
            size="small"
            label="Name"
            value={loan.name}
            onChange={(e) => {
              loan.name = e.target.value;
              setLoan(copy(loan));
            }}
            InputLabelProps={{ shrink: true }}
          />
          <Autocomplete
            size="small"
            options={workflows ?? []}
            autoComplete
            autoHighlight
            value={workflow}
            onChange={(e, value) => setWorkflow(value)}
            getOptionLabel={(option) => StringUtils.capitalize(option.name)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={"Workflow"}
                InputLabelProps={{
                  shrink: true,
                  style: { color: "#4A4A4A", fontSize: "16px" },
                }}
                sx={{
                  "& .MuiInputBase-input": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: {
                    fontSize: "14px",
                    color: "black",
                    backgroundColor: "white",
                  },
                }}
              />
            )}
          />
        </div>
        <div className="h-30 center-text red bold">{toaster}</div>
        <SubmitInput className={"tertiary-color-scheme-1"} value={"Create"} />
      </form>
    </div>
  );
};

export default AddLoan;
