import { FiSettings } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";
import { useState, useContext, useMemo } from "react";
import { AppContext } from "../../Contexts/Contexts";
import ProfilePopup from "./components/ProfilePopup";
import { NavLink } from "react-router-dom";
import ReportIcon from "../Icons/ReportIcon";
import GenerateUsageReport from "../Reports/GenerateUsageReport";
import TrueLogo from "../Icons/TrueLogo";
import BrowserUtils from "../../utils/browser.utils";
import DomUtils from "../../utils/dom.utils";
import { useLocation } from "react-router-dom";

const ICON_SIZE = 22;

const NavBar = () => {
  const { user, leftPopOut } = useContext(AppContext);
  const [showProfile, setShowProfile] = useState(false);
  const location = useLocation();
  const params = useMemo(
    () => BrowserUtils.getServiceParams(location.search),
    [location.search]
  );

  const onReport = () => {
    leftPopOut.open(
      DomUtils.getOrangeBlackHeader("Generate", "Usage Report"),
      <GenerateUsageReport />
    );
  };

  return (
    <nav className="bg-black flex space-between pad-5-10 align-center">
      <NavLink
        className={params.isStandalone ? "disabled-link" : ""}
        to="/loans"
        style={{ width: "fit-content" }}
      >
        <TrueLogo color={"white"} size={100} />
      </NavLink>
      <div className="flex gap-10 font-14 align-center">
        {/* Temporarily hidden until admin is ready */}
        <NavLink hidden={true} to="/admin">
          <FiSettings size={ICON_SIZE} color="white" title="Settings" />
        </NavLink>
        {user?.IsAdministrator && (
          <ReportIcon
            size={ICON_SIZE}
            onClick={onReport}
            title="Report Tools"
            className={"button"}
          />
        )}
        <div
          onMouseEnter={() => setShowProfile(true)}
          onMouseLeave={() => setShowProfile(false)}
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <ProfilePopup hide={!showProfile} />
          <CgProfile
            id="profile-icon"
            size={ICON_SIZE}
            style={{ cursor: "pointer", zIndex: "2" }}
            color="white"
            title="Profile"
          />
        </div>
        <div>{user?.username}</div>
      </div>
    </nav>
  );
};

export default NavBar;
