import { TextField } from "@mui/material";
import React, { useState } from "react";
import SubmitInput from "../Inputs/SubmitInput";
import { DesktopDatePicker as DatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import BrowserUtils from "../../utils/browser.utils";

const GenerateUsageReport = () => {
  const [date, setDate] = useState(new Date());

  const differenceInMonths = (date1, date2) => {
    const year1 = date1.getFullYear();
    const year2 = date2.getFullYear();
    const month1 = date1.getMonth();
    const month2 = date2.getMonth();

    let months = (year2 - year1) * 12 + (month2 - month1);

    if (date2.getDate() < date1.getDate()) {
      months--;
    }

    return months;
  };

  // onClicks
  const onSubmit = async (e) => {
    e.preventDefault();
    const monthsAgo = differenceInMonths(date, new Date());
    const report = "not implemented" ?? monthsAgo; // TODO implement usage report
    const monthAsNumber = date.getMonth() + 1;
    BrowserUtils.downloadObjectAsJson(
      report,
      `usage-report-${date.getFullYear()}-${monthAsNumber
        .toString()
        .padStart(2, "0")}`
    );
  };

  return (
    <div className="standard-content">
      <form onSubmit={onSubmit} className="flex-col-sb h-full w-300">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            views={["year", "month"]}
            label="Month and Year"
            minDate={new Date().setMonth(new Date().getMonth() - 2)}
            maxDate={new Date()}
            value={date}
            onChange={setDate}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <div className="flex-center">
          <SubmitInput value="Download" />
        </div>
      </form>
    </div>
  );
};

export default GenerateUsageReport;
