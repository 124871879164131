import { LuExpand } from "react-icons/lu";
import PropTypes from "prop-types";

const ExpandIcon = ({ onClick, size, sx, className, title }) => {
  return (
    <LuExpand
      className={`icon ${className}`}
      size={size}
      onClick={onClick}
      style={sx}
      title={title}
    />
  );
};

ExpandIcon.defaultProps = {
  onClick: () => {},
  sx: {},
  className: "",
  size: 30,
  title: "Expand",
};

ExpandIcon.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
  sx: PropTypes.object,
  className: PropTypes.string,
};
export default ExpandIcon;
