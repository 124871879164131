const CircleStat = ({ style, className, title, value }) => {
  return (
    <div className="flex-col center w-fit gap-5">
      <div className="bold font-14">{title}</div>
      <div className="flex-center">
        <div className={`flex-center br-half s-100 ${className}`} style={style}>
          <div>{value}</div>
        </div>
      </div>
    </div>
  );
};

CircleStat.defaultProps = {
  style: {},
  className: "",
  title: "Circle Stat",
  value: "value",
};

export default CircleStat;
