import { BsDownload } from "react-icons/bs";
import PropTypes from "prop-types";

const DownloadIcon = ({ onClick, size, sx, className }) => {
  return (
    <BsDownload
      className={`download-icon ${className}`}
      size={size}
      onClick={onClick}
      style={sx}
    />
  );
};

DownloadIcon.defaultProps = {
  size: 30,
  onClick: () => {},
  sx: {},
  className: "",
};

DownloadIcon.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
  sx: PropTypes.object,
  className: PropTypes.string,
};

export default DownloadIcon;
