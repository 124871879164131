import React, { useCallback, useContext } from "react";
import { AppContext } from "../../../Contexts/Contexts";
import TableConfig from "./TableConfig";
import ExpandableTable from "../../../components/Tables/ExpandableTable";
import StandardTable from "../../../components/Tables/StandardTable";
import Viewer from "../../../components/Viewers/Viewer";
import BrowserUtils from "../../../utils/browser.utils";
import DomUtils from "../../../utils/dom.utils";
import { isEmpty } from "../../../utils/general.utils";

const MonthlyIncomeTable = ({ loan, docs, incomeTypes, hidden }) => {
  const { alert, leftPopOut } = useContext(AppContext);

  const onElementSelected = useCallback(
    async (element) => {
      const doc = loan.documents[element.docId];
      if (!doc) {
        alert.open(<div>Document not found.</div>);
        return;
      }
      const pages = Object.values(doc.pages).map((page) => {
        return {
          ...page,
          src: BrowserUtils.getImageUrl(loan.name, page.fileId),
        };
      });
      const field = doc.fields[element.fieldId];
      const page = field ? doc.pages[field.pageId] : null;
      leftPopOut.open(
        DomUtils.getOrangeBlackHeader("Document", doc.type),
        <Viewer
          images={pages}
          index={page?.number - 1}
          coordinates={field?.coordinates}
          dimensions={{ width: page?.width, height: page?.height }}
          className={"w-half-screen"}
        />
      );
    },
    [loan, alert, leftPopOut]
  );

  const getRows = useCallback(() => {
    const filteredRows = [];
    for (const incomeType of incomeTypes) {
      const rows = [];
      for (const element of incomeType.elements) {
        let row = {};
        const doc = loan.documents[element.docId];
        if (doc) {
          row.docId = doc._id;
          row.docType = doc.type;
          const field = doc.fields[element.fieldId];
          if (field) {
            row.fieldId = field._id;
            row.fieldName = field.name;
            row.fieldConf = field.confidence;
            row.value = field.value;
          }
        }
        if (!isEmpty(row)) {
          rows.push(row);
        }
      }
      if (!isEmpty(rows)) {
        incomeType.expand = (
          <StandardTable
            tableConfig={TableConfig.elements}
            onIconClicks={{ view: onElementSelected }}
            rowData={rows}
            containerOffset="500px"
            className="box-shadow"
          />
        );
        filteredRows.push(incomeType);
      }
    }
    return filteredRows;
  }, [onElementSelected, incomeTypes, loan]);

  return (
    <div className="overflow-hidden box-shadow" hidden={hidden}>
      <ExpandableTable
        tableConfig={TableConfig.incomeTypes}
        rowData={getRows()}
        showFooter
      />
    </div>
  );
};

MonthlyIncomeTable.defaultProps = {
  incomeTypes: [],
};

export default MonthlyIncomeTable;
