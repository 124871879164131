import DomUtils from "./dom.utils";

export function copy(obj) {
  const validJsonObject = DomUtils.filterOutJsx(obj);
  return JSON.parse(JSON.stringify(validJsonObject));
}

export function sleep(seconds) {
  const ms = seconds * 1000;
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isEmpty(obj) {
  return (
    obj === "" ||
    obj === null ||
    obj === undefined ||
    (Object.prototype.toString.call(obj) === "[object Object]" &&
      Object.keys(obj).length === 0) ||
    (Array.isArray(obj) && obj.length === 0)
  );
}
