import StandardAttribute from "../../../components/Attributes/StandardAttribute";

const AuditReportMetadata = ({ report }) => {
  return (
    <div className="flex bg-lg pad-5 gap-30">
      <StandardAttribute
        label="Total Fields"
        data={report?.NumberOfFields ?? 0}
      />
      <StandardAttribute label="Mismatches" data={report?.Mismatches ?? 0} />
      <StandardAttribute label="Warnings" data={report?.Warnings ?? 0} />
      <StandardAttribute
        label="Matched"
        data={report ? `${report?.PercentMatched}%` : 0}
      />
    </div>
  );
};

export default AuditReportMetadata;
