import { useContext } from "react";
import { ServiceContext } from "../../../Contexts/Contexts";
import CircleStat from "../../../components/Stats/CircleStat";

const AnalyticsPanel = () => {
  const { state } = useContext(ServiceContext);

  return (
    <div className="flex-col gap-30 pad-10 bg-lg s-full">
      <div className="flex-col gap-10 ">
        <h1>Documents</h1>
        <div className="flex gap-20">
          <CircleStat
            className={"bg-blue"}
            title={"Total"}
            value={state.loan.documents.length}
          />
          <CircleStat
            className={"bg-blue"}
            title="Reclassified"
            value={
              state.loan.documents.filter(
                (doc) => doc.type !== doc.originalType
              ).length
            }
          />
          <CircleStat
            className={"bg-blue"}
            title={"Average Confidence"}
            value={`${
              state.loan.documents.reduce(
                (acc, doc) => acc + doc.originalConfidence,
                0
              ) / state.loan.documents.length
            }%`}
          />
        </div>
      </div>
      <div className="flex-col gap-10">
        <h1>Versions</h1>
        <div className="flex gap-20">
          <CircleStat
            className={"bg-blue"}
            title="Re-Versioned"
            value={
              state.loan.documents.filter(
                (doc) => doc.version !== doc.originalVersion
              ).length
            }
          />
          <CircleStat
            className={"bg-blue"}
            title={"Average Confidence"}
            value={`${
              state.loan.documents.reduce(
                (acc, doc) => acc + doc.originalVersionConfidence,
                0
              ) / state.loan.documents.length
            }%`}
          />
        </div>
      </div>
      <div className="flex-col gap-10">
        <h1>Fields</h1>
        <div className="flex gap-20">
          <CircleStat
            className={"bg-blue"}
            title={"Total"}
            value={state.loan.documents.reduce(
              (acc, doc) => acc + doc.fields.length,
              0
            )}
          />
          <CircleStat className={"bg-blue"} title="Changed" value={0} />
          <CircleStat
            className={"bg-blue"}
            title={"Average Confidence"}
            value={`75%`}
          />
        </div>
      </div>
    </div>
  );
};

export default AnalyticsPanel;
