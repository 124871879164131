import { useContext, useState } from "react";
import { FileInput } from "../../../components/Inputs/FileInput";
import { AppContext } from "../../../Contexts/Contexts";
import SubmitInput from "../../../components/Inputs/SubmitInput";
import ApiClient from "../../../clients/api.client";
import { Autocomplete, TextField } from "@mui/material";
import StringUtils from "../../../utils/string.utils";

const AddFiles = ({ loan }) => {
  const { loader, showCheckmark, leftPopOut, alert, workflows } =
    useContext(AppContext);
  const [files, setFiles] = useState([]);
  const [workflow, setWorkflow] = useState(
    workflows.find((c) => c.name === "default")
  );
  const [toaster, setToaster] = useState("");

  const addFiles = (newFiles) => {
    for (const file of newFiles) {
      files.push(new File([file], file.name, { type: file.type }));
    }
    setFiles([...files]);
    document.getElementById("files").value = null;
  };

  const removeFile = (oldFile) => {
    setFiles((files) => {
      files.splice(files.indexOf(oldFile), 1);
      return [...files];
    });
  };

  const upload = async (e) => {
    setToaster("");
    e.preventDefault();
    if (!workflow) {
      setToaster("No Workflow Selected");
      return;
    }
    if (files.length === 0) {
      return;
    }
    leftPopOut.close();
    try {
      loader.open();
      await ApiClient.uploadFiles(loan._id, files, workflow._id);
    } catch (error) {
      alert.open(<div>{error.toString()}</div>);
      return;
    } finally {
      loader.close();
    }
    await showCheckmark();
  };

  return (
    <div className="border-top-green box-shadow h-full pad-10-5">
      <form onSubmit={upload} className="h-full w-300 flex-col gap-5">
        <Autocomplete
          size="small"
          options={workflows ?? []}
          autoComplete
          autoHighlight
          value={workflow}
          onChange={(e, value) => setWorkflow(value)}
          getOptionLabel={(option) => StringUtils.capitalize(option.name)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={"Workflow"}
              InputLabelProps={{
                shrink: true,
                style: { color: "#4A4A4A", fontSize: "16px" },
              }}
              sx={{
                "& .MuiInputBase-input": {
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              }}
              InputProps={{
                ...params.InputProps,
                style: {
                  fontSize: "14px",
                  color: "black",
                  backgroundColor: "white",
                },
              }}
            />
          )}
        />
        <div className="center-text red bold h-30">{toaster}</div>
        <FileInput files={files} addFiles={addFiles} removeFile={removeFile} />
        <SubmitInput value="Upload" />
      </form>
    </div>
  );
};

export default AddFiles;
