const CircleLoader = ({ loaderProps }) => {
  return (
    <>
      <div className="loader" />
      {loaderProps.content && (
        <div className="loader-content">{loaderProps.content}</div>
      )}
    </>
  );
};

export default CircleLoader;
