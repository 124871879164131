import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import DownIcon from "../Icons/DownIcon";
import RightIcon from "../Icons/RightIcon";
import { Box } from "@mui/system";
import EnhancedTableHead from "../EnhancedTableHead";
import CompareUtils from "../../utils/compare.utils";
import ObjectUtils from "../../utils/object.utils";
import LinkedCell from "../Cells/old.LinkedCell";

const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          {isExpanded ? (
            <DownIcon
              size={20}
              onClick={() => setIsExpanded(!isExpanded)}
              className={
                "button bg-green br-5 box-shadow-c vertical-align-middle"
              }
            />
          ) : (
            <RightIcon
              size={20}
              onClick={() => {
                setIsExpanded(!isExpanded);
              }}
              className={
                "button bg-green br-5 box-shadow-c vertical-align-middle"
              }
            />
          )}
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell colSpan="100%">{expandComponent}</TableCell>
        </TableRow>
      )}
    </>
  );
};

const ExpandableTable = ({
  tableConfig,
  rowData,
  onRowClick,
  highlightRow,
  rowButton,
  containerOffset,
  showFooter,
  onIconClicks,
  className,
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(tableConfig[0]?.id);
  const [orderType, setOrderType] = useState(tableConfig[0]?.type || "string");
  // const [selected, setSelected] = useState([]);
  // const [page, setPage] = useState(0);
  const page = 0;
  const showRowIndex = -1;
  // const [rowsPerPage, setRowsPerPage] = useState(1000);
  const rowsPerPage = 1000;

  const getRowBgColor = (idx) => {
    return idx % 2 ? "white" : "var(--light-gray)";
  };

  const highlight = (row) => {
    return highlightRow === row[orderBy] ? "selected" : "";
  };

  const containerHeight = () => {
    return containerOffset ? `calc(100vh - ${containerOffset})` : "100%";
  };

  const handleRequestSort = (event, property, type) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setOrderType(type);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowData.length) : 0;

  return (
    <Box className={`standard-table-container ${className}`}>
      <Paper sx={{ width: "100%", height: "100%" }}>
        <TableContainer
          sx={{
            overflow: "auto",
            maxHeight: containerHeight(),
            borderBottom: "1px solid var(--light-gray)",
          }}
          className={"scrollbar"}
        >
          <Table aria-labelledby="tableTitle" size={"small"} stickyHeader>
            <EnhancedTableHead
              numSelected={0}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rowData.length}
              tableConfig={tableConfig}
              rowButton={rowButton}
              expand
            />
            <TableBody>
              {rowData
                .slice()
                .sort(CompareUtils.getComparator(order, orderBy, orderType))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, idx) => {
                  return (
                    <ExpandableTableRow
                      expandComponent={row.expand}
                      className={highlight(row)}
                      style={{
                        backgroundColor: getRowBgColor(idx),
                      }}
                      hover
                      onClick={() => {
                        onRowClick(row);
                      }}
                      tabIndex={-1}
                      key={row.expand.key || idx}
                    >
                      {tableConfig.map((header, idx) => {
                        let data;
                        if (header.function) {
                          data = header.function(row[header.id]).toString();
                        } else {
                          data = ObjectUtils.getNestedValue(row, header.id);
                        }
                        return (
                          <TableCell key={idx} className="table-cell">
                            <LinkedCell
                              row={row}
                              data={data}
                              config={header}
                              onClicks={onIconClicks}
                            />
                          </TableCell>
                        );
                      })}
                      {rowButton != null && (
                        <TableCell className="edit-button">
                          {idx === showRowIndex
                            ? rowButton({ visibility: "visible" })
                            : rowButton({ visibility: "hidden" })}
                        </TableCell>
                      )}
                    </ExpandableTableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {showFooter && (
              <TableFooter>
                <TableRow>
                  <TableCell />
                  {tableConfig.map((columnConfig, idx) => {
                    let data = "";
                    if (columnConfig.footer) {
                      const columnData = rowData.map((row) => {
                        return row[columnConfig.id];
                      });
                      data = columnConfig.footer(columnData).toString();
                    }
                    return (
                      <TableCell
                        key={idx}
                        sx={{
                          color: "black",
                          fontFamily: "var(--font-family-sans-serif)",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        {data}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[15]}
          component="div"
          count={rowData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton={true}
        /> */}
      </Paper>
    </Box>
  );
};

ExpandableTable.defaultProps = {
  highlightRow: null,
  rowButton: null,
  onRowClick: () => {},
  onIconClicks: {},
  className: "",
  rowData: [],
};

export default ExpandableTable;
