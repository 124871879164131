import Viewer from "../../components/Viewers/Viewer";
import BrowserUtils from "../../utils/browser.utils";

const props = BrowserUtils.localStorage.get("focus") || {};
const { images, index, coordinates, dimensions } = props;
BrowserUtils.localStorage.delete("focus");

const Focus = () => {
  return (
    <div className="flex h-full w-full justify-center pad-5">
      {props.images ? (
        <Viewer
          images={images}
          index={index}
          coordinates={coordinates}
          dimensions={dimensions}
          isFullScreen
        />
      ) : (
        <div className="flex justify-center pad-30">Focus Mode Unavailable</div>
      )}
    </div>
  );
};

export default Focus;
