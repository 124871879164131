import PropTypes from "prop-types";
import { IoStatsChartSharp } from "react-icons/io5";

const AnalyticsIcon = ({ size, className, onClick, title }) => {
  return (
    <IoStatsChartSharp
      className={`no-shrink  ${className}`}
      size={size}
      title={title}
      onClick={onClick}
    />
  );
};

AnalyticsIcon.defaultProps = {
  size: 30,
  className: "",
  onClick: () => {},
  title: "Analytics",
};

AnalyticsIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

export default AnalyticsIcon;
