import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../../Contexts/Contexts";
import ApiClient from "../../../clients/api.client";
import Profile from "../../../pages/Profile/Profile";
import DomUtils from "../../../utils/dom.utils";

const ProfilePopup = ({ hide, className }) => {
  const { setUser, loader, leftPopOut, user } = useContext(AppContext);
  const navigate = useNavigate();
  const logout = async () => {
    loader.open();
    await ApiClient.logout();
    setUser(null);
    navigate("/login");
    loader.close();
  };

  // onClicks
  const onProfile = () => {
    leftPopOut.open(
      DomUtils.getOrangeBlackHeader("Profile", user.username),
      <Profile />
    );
  };

  return (
    <nav hidden={hide} className={`profile-popup ${className}`}>
      <button className="green-hover" onClick={onProfile}>
        Profile
      </button>
      <button className="green-hover" onClick={logout}>
        Log Out
      </button>
    </nav>
  );
};

ProfilePopup.defaultProps = {
  className: "",
};

export default ProfilePopup;
