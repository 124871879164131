import { useCallback, useEffect, useState } from "react";
import ObjectUtils from "../../utils/object.utils";

const pad = 2;
const minZoom = 2;
const animationTimeMs = 150;

const cssValue = (numValue) => `${numValue}px`;

const BoundingBox = ({
  viewerWrapperRect,
  viewerContentRect,
  imageRect,
  coordinates,
  dimensions,
  setTransform,
  currentZoom,
}) => {
  const [box, setBox] = useState({ top: 0, left: 0, width: 0, height: 0 });

  const getBox = useCallback(() => {
    const { top, left, right, bottom } = coordinates;
    if (!imageRect || !top || !left || !right || !bottom) {
      return {};
    }

    const scale = {
      width: imageRect.width / dimensions.width / currentZoom,
      height: imageRect.height / dimensions.height / currentZoom,
    };

    const getAdjustedCoordinates = (value, scale) => {
      const number = value * scale - pad;
      return Number(number.toFixed(2));
    };
    const getAdjustedSize = (value, scale) => {
      const number = value * scale + pad * 2;
      return Number(number.toFixed(2));
    };

    const box = {
      top: getAdjustedCoordinates(top, scale.width),
      left: getAdjustedCoordinates(left, scale.height),
      width: getAdjustedSize(right - left, scale.width),
      height: getAdjustedSize(bottom - top, scale.height),
    };

    return box;
  }, [coordinates, imageRect, dimensions, currentZoom]);

  const moveTransform = useCallback(
    (box) => {
      const zoom = Math.max(currentZoom, minZoom);

      const viewerCenter = {
        x: viewerWrapperRect.width / 2,
        y: viewerWrapperRect.height / 2,
      };

      const boxCenter = {
        x: (box.left + box.width / 2 + pad) * zoom,
        y: (box.top + box.height / 2 + pad) * zoom,
      };

      const imageOffset = {
        x:
          ((viewerContentRect.width - imageRect.width) / 2) *
          (zoom / currentZoom),
        y:
          ((viewerContentRect.height - imageRect.height) / 2) *
          (zoom / currentZoom),
      };

      const x = viewerCenter.x - imageOffset.x - boxCenter.x;
      const y = viewerCenter.y - imageOffset.y - boxCenter.y;

      setTransform(x, y, zoom, animationTimeMs, "linear");
    },
    [currentZoom, setTransform, viewerWrapperRect, viewerContentRect, imageRect]
  );

  useEffect(() => {
    const newBox = getBox();
    if (!ObjectUtils.isEqual(newBox, box)) {
      setBox(newBox);
      if (newBox.width && newBox.height) {
        moveTransform(newBox);
      }
    }
  }, [getBox, box, moveTransform]);

  return (
    box.width && (
      <div
        className="bounding-box"
        style={{
          top: cssValue(box.top),
          left: cssValue(box.left),
          width: cssValue(box.width),
          height: cssValue(box.height),
        }}
      />
    )
  );
};

BoundingBox.defaultProps = {
  coordinates: {},
};

export default BoundingBox;
