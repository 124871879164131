const TableConfig = {
  globals: [
    {
      id: "SortId",
      label: "Sort ID",
      type: "number",
    },
    {
      id: "Category",
      label: "Category",
      type: "string",
    },
    {
      id: "FieldName",
      label: "Field Name",
      type: "string",
    },
    {
      id: "Value",
      label: "Value",
      type: "string",
    },
    {
      id: "NumberOfMismatches",
      label: "Mismatches",
      type: "number",
    },
    {
      id: "NumberOfWarnings",
      label: "Warnings",
      type: "number",
    },
  ],
  locals: [
    {
      id: "DocumentType",
      label: "Document Type",
    },
    {
      id: "Version",
      label: "Version",
    },
    {
      id: "FieldName",
      label: "Field Name",
      action: "view",
    },
    {
      id: "ExtractedValue",
      label: "Value",
    },
    {
      id: "Result",
      label: "Result",
    },
  ],
};

export default TableConfig;
