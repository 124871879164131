import AuditReportMetadata from "../AuditComponents/AuditReportMetadata";
import GlobalsTable from "../AuditComponents/GlobalsTable";

const AuditPanel = ({ report, indexedLoan }) => {
  return (
    <div className="s-full relative">
      <div
        className={`flex-col gap-5 w-full h-full overflow-hidden ${
          report ? "" : "block-events"
        }`}
      >
        <AuditReportMetadata report={report} />
        <div className="bg-lg pad-5 h-full w-full overflow-hidden">
          <GlobalsTable
            verifications={report?.Verifications}
            loan={indexedLoan}
          />
        </div>
      </div>
      {!report && (
        <div className="absolute bg-blue white box-shadow pad-10 br-5 serif font-24 transform-center">
          Select a report from the list.
        </div>
      )}
    </div>
  );
};

export default AuditPanel;
