import { FaRegCircle } from "react-icons/fa";
import { FaRegDotCircle } from "react-icons/fa";
import PropTypes from "prop-types";

const CircleIcon = ({ onClick, size, sx, className, title, selected }) => {
  return selected ? (
    <FaRegDotCircle
      className={`${className} no-shrink`}
      size={size}
      onClick={onClick}
      style={sx}
      title={title}
    />
  ) : (
    <FaRegCircle
      className={`${className} no-shrink`}
      size={size}
      onClick={onClick}
      style={sx}
      title={title}
    />
  );
};

CircleIcon.defaultProps = {
  size: 30,
  onClick: () => {},
  sx: {},
  className: "",
  title: "Circle",
  selected: false,
};

CircleIcon.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
  sx: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string,
  selected: PropTypes.bool,
};
export default CircleIcon;
