import PropTypes from "prop-types";
import { TbVersions } from "react-icons/tb";

const VersioningIcon = ({ onClick, size, sx, className, title }) => {
  return (
    <TbVersions
      className={`icon ${className}`}
      size={size}
      onClick={onClick}
      style={sx}
      title={title}
    />
  );
};

VersioningIcon.defaultProps = {
  size: 30,
  sx: {},
  onClick: () => {},
  className: "",
  title: "Version",
};

VersioningIcon.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
  className: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.string,
};

export default VersioningIcon;
