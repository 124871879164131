class ObjectUtils {
  static getNestedValue(obj, path) {
    if (!obj) return undefined;
    const keys = Array.isArray(path) ? path : path.split(".");
    return keys.reduce((currentObject, key) => {
      return currentObject ? currentObject[key] : undefined;
    }, obj);
  }

  static isEqual(obj1, obj2) {
    if (obj1 === obj2) {
      return true;
    }

    if (
      typeof obj1 !== "object" ||
      typeof obj2 !== "object" ||
      obj1 == null ||
      obj2 == null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (!keys2.includes(key)) {
        return false;
      }

      if (!ObjectUtils.isEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }
}

export default ObjectUtils;
