import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../Contexts/Contexts";
import services, { getIdFromTrapezeId } from "../../store/services";
import EyeIcon from "../Icons/EyeIcon";
import ApiClient from "../../clients/api.client";

const getValidationStage = (stage, status, assignedTo, user) => {
  const validStatus = new Set(["updated", "processing", "migrated"]);
  let isReserved;
  if (status) {
    isReserved =
      status.toLowerCase() === "processing" && user.Username !== assignedTo;
  }
  if (!validStatus.has(status) || assignedTo.toLowerCase() === "system")
    return null;

  switch (stage) {
    case "DataVerification":
      if (status.toLowerCase() === "processing") return null;
      return "verifai";
    case "IncomeAnalysis":
      return "income_analyzer";
    case "ClassificationQueue":
      if (isReserved && !user.IsAdministrator) return null;
      return "classification";
    case "VersionQueue":
      return "versioning";
    case "Final":
    case "Validation":
      return "extraction";
    default:
      return null;
  }
};

const LinkedCell = ({ row, data, config, onClicks, tableType }) => {
  const navigate = useNavigate();
  const { user, confirm } = useContext(AppContext);
  const validationStage = getValidationStage(
    row.stage,
    row.status,
    row.assignedTo,
    user
  );
  let isReserved;
  if (row.status) {
    isReserved =
      row.status.toLowerCase() === "processing" &&
      user.Username !== row.assignedTo;
  }

  const navigateToService = useCallback(
    (batchName, stage) => {
      navigate(
        `/services?id=${batchName}&service=${getIdFromTrapezeId(stage)}`
      );
    },
    [navigate]
  );

  const onAdminOverride = async (serviceName, row, onClicks) => {
    const service = services[serviceName];
    let release;
    switch (service.id) {
      case "classification":
        release = () => services.classification.release(row.name);
        break;
      case "extraction":
        release = () => services.extraction.release(row.name, row);
        break;
      case "versioning":
        release = () => services.versioning.release(row.batchName, row);
        break;
      default:
        break;
    }

    const message = `This ${service.itemType} is assigned to user ${row.assignedTo} who may be actively working on it. Are you sure you want to reassign it to yourself?`;
    confirm.open(<p>{message}</p>, async () => {
      await release();
      onClicks();
    });
  };

  const layout = () => {
    switch (config.action) {
      case "view":
        const disableIcon =
          tableType !== "dashboard" && isReserved && !user.IsAdministrator;
        return (
          <>
            <EyeIcon
              size={20}
              onClick={async () => {
                if (
                  isReserved &&
                  user.IsAdministrator &&
                  tableType !== "dashboard"
                ) {
                  await onAdminOverride(validationStage, row, () =>
                    onClicks[config.action](row)
                  );
                } else {
                  onClicks[config.action](row);
                }
              }}
              disabled={disableIcon}
              className={"bg-green button br-10 pad-2"}
            />
            <div className="ellipses">{data}</div>
          </>
        );
      case "edit":
        if (!validationStage) {
          return data;
        } else if (validationStage === "verifai") {
          // if we are using loan based system, hide edit button and use services button instead
          if (new URLSearchParams().has("id")) return <div>{data}</div>;
          return (
            <div
              className="hyperlink"
              onClick={async () => {
                const loan = await ApiClient.getLoanFromBatchName(row.name);
                navigate(`/services?id=${loan._id}&verifai=multi-service`);
              }}
            >
              {data}
            </div>
          );
        } else {
          return (
            <div
              className="hyperlink"
              onClick={() => {
                if (
                  isReserved &&
                  user.IsAdministrator &&
                  validationStage === "classification"
                ) {
                  onAdminOverride(validationStage, row, () =>
                    navigateToService(row.name, row.stage)
                  );
                } else {
                  navigateToService(row.name, row.stage);
                }
              }}
            >
              {data}
            </div>
          );
        }
      default:
        return data;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
      }}
    >
      {layout()}
    </div>
  );
};

LinkedCell.defaultProps = {
  config: {},
  onClicks: {},
};

export default LinkedCell;
