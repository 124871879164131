import StandardAttribute from "../../../components/Attributes/StandardAttribute";
import StringUtils from "../../../utils/string.utils";

const getReportColor = (result) => {
  switch (result) {
    case "Failed":
      return "bg-red";
    case "Review Required":
      return "bg-blue";
    case "Income Supported":
      return "bg-green";
    default:
      return "bg-black";
  }
};

const IncomeReportMetaData = ({ report }) => {
  return (
    <div className="flex bg-lg gap-30 pad-5 align-center font-14">
      <div className={`pad-5 br-5 ${getReportColor(report?.result)}`}>
        {report?.result ?? "N/A"}
      </div>
      {/* <FlippedAttribute label="Confidence" data={report.confidence} /> */}
      <StandardAttribute
        label="Borrower"
        data={StringUtils.toTitleCase(report?.borrower) ?? "N/A"}
      />
      <StandardAttribute
        label="Employer"
        data={StringUtils.toTitleCase(report?.employer) ?? "N/A"}
      />
      <StandardAttribute label="Logic Path" data={report?.logicPath ?? "N/A"} />
    </div>
  );
};

export default IncomeReportMetaData;
