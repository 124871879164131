import { copy } from "./general.utils";

class ArrayUtils {
  static createIndex(arr, key) {
    return arr.reduce((obj, item) => {
      obj[item[key]] = item;
      return obj;
    }, {});
  }
  static groupBy(arr, prop) {
    return arr.reduce((acc, obj) => {
      const key = obj[prop];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }
  static groupedArray(arr, prop, contentName) {
    const obj = ArrayUtils.groupBy(arr, prop);
    const arrOfObjs = [];
    for (const [name, content] of Object.entries(obj)) {
      const obj = { name: name };
      obj[contentName] = content;
      arrOfObjs.push(obj);
    }
    return arrOfObjs;
  }
  static getIndexedLoan(loan) {
    loan = copy(loan);
    loan.documents = ArrayUtils.createIndex(loan.documents, "_id");
    for (const docId of Object.keys(loan.documents)) {
      loan.documents[docId].fields = ArrayUtils.createIndex(
        loan.documents[docId].fields,
        "_id"
      );
      loan.documents[docId].pages = ArrayUtils.createIndex(
        loan.documents[docId].pages,
        "_id"
      );
    }
    return loan;
  }
}

export default ArrayUtils;
