import AuditIcon from "../components/Icons/AuditIcon";
import InfoIcon from "../components/Icons/InfoIcon";
import ClassificationIcon from "../components/Icons/ClassificationIcon";
import ExtractionIcon from "../components/Icons/ExtractionIcon";
import IncomeIcon from "../components/Icons/IncomeIcon";
import VersioningIcon from "../components/Icons/VersioningIcon";
import AuditService from "../pages/Services/audit.service";
import ClassificationService from "../pages/Services/classification.service";
import IncomeService from "../pages/Services/income.service";
import ExtractionService from "../pages/Services/extraction.service";
import VersioningService from "../pages/Services/versioning.service";
import LoanDetails from "../pages/Loan/LoanDetails";
import AnalyticsIcon from "../components/Icons/AnalyticsIcon";
import AnalyticsPanel from "../pages/Services//Panels/analytics.panel";

const Services = {
  details: {
    id: "details",
    label: "Loan Details",
    itemType: "loan",
    tableName: `Documents`,
    icon: <InfoIcon />,
    component: <LoanDetails />,
  },
  analytics: {
    id: "analytics",
    label: "Analytics",
    itemType: "tbd",
    tableName: `TBD`,
    icon: <AnalyticsIcon />,
    component: <AnalyticsPanel />,
  },
  classification: {
    id: "classification",
    label: "Classification",
    itemType: "loan",
    tableName: `Documents`,
    icon: <ClassificationIcon />,
    component: <ClassificationService />,
  },
  versioning: {
    id: "versioning",
    label: "Versioning",
    itemType: "document type",
    tableName: `Document Types`,
    icon: <VersioningIcon />,
    component: <VersioningService />,
  },
  extraction: {
    id: "extraction",
    label: "Extraction",
    itemType: "document",
    tableName: `Documents`,
    icon: <ExtractionIcon />,
    component: <ExtractionService />,
  },
  income: {
    id: "income",
    label: "Income",
    itemType: "report",
    tableName: `Reports`,
    icon: <IncomeIcon />,
    component: <IncomeService />,
  },
  audit: {
    id: "audit",
    label: "Data Verification",
    itemType: "report",
    tableName: `Reports`,
    icon: <AuditIcon />,
    component: <AuditService />,
  },
};

export const getIdFromTrapezeId = (trapezeId) => {
  switch (trapezeId) {
    case Services.classification.trapezeId:
      return Services.classification.id;
    case Services.versioning.trapezeId:
      return Services.versioning.id;
    case Services.extraction.trapezeId[0]:
    case Services.extraction.trapezeId[1]:
      return Services.extraction.id;
    case Services.income.trapezeId:
      return Services.income.id;
    case Services.audit.id:
      return Services.audit.id;
    default:
      return trapezeId;
  }
};

export default Services;
