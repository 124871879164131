import { FaPlus } from "react-icons/fa6";

const AddIcon = ({ size, className, title, hidden, onClick }) => {
  return (
    <FaPlus
      className={className}
      size={size}
      title={title}
      hidden={hidden}
      onClick={onClick}
    />
  );
};

AddIcon.defaultProps = {
  className: "",
  title: "Add",
  onClick: () => {},
};

export default AddIcon;
