import { AiOutlineSearch } from "react-icons/ai";

const SearchIcon = ({ size, className, title, onClick }) => {
  return (
    <AiOutlineSearch
      className={className}
      size={size}
      title={title}
      onClick={onClick}
    />
  );
};

SearchIcon.defaultProps = {
  className: "",
  title: "Search",
  onClick: () => {},
};

export default SearchIcon;
