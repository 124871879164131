import { AiOutlineInbox } from "react-icons/ai";

const BatchIcon = ({ size, className, onClick, title }) => {
  return (
    <AiOutlineInbox
      size={size}
      className={`icon ${className}`}
      onClick={onClick}
      title={title}
    />
  );
};

BatchIcon.defaultProps = {
  size: 20,
  className: "",
  onClick: () => {},
  title: "",
};

export default BatchIcon;
