class StringUtils {
  static indexToLetter(index) {
    if (index < 0) {
      throw new Error(
        `Index out of range. Index must be non-negative. Index: ${index}`
      );
    }

    let result = "";
    while (index >= 0) {
      const charCode = index % 26;
      result = String.fromCharCode(65 + charCode) + result;
      index = Math.floor(index / 26) - 1;
    }

    return result;
  }
  static capitalize(str) {
    if (!str || str === "") return str;
    return str.toLowerCase().replace(str[0], str[0].toUpperCase());
  }
  static toTitleCase(str) {
    if (!str || str === "") return str;
    const stringBuilder = [];
    const words = str.split(" ");
    for (const word of words) {
      stringBuilder.push(StringUtils.capitalize(word));
    }
    return stringBuilder.join(" ");
  }
  static getShortHash(hash) {
    return hash.substring(21, 24);
  }
  static getLocalPartOfEmail = (email) => {
    const index = email.indexOf("@");
    if (index === -1) {
      return email;
    }
    return email.substring(0, index);
  };
  static formatUsd(num) {
    return Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(num);
  }
  static truncate(str, maxLength) {
    if (str.length <= maxLength) return str;
    return str.substring(0, maxLength) + "...";
  }
}

export default StringUtils;
