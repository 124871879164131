import { HiPencilSquare } from "react-icons/hi2";
import PropTypes from "prop-types";

const ModifiedIcon = ({ onClick, size, sx, className, title }) => {
  return (
    <HiPencilSquare
      className={`icon ${className}`}
      size={size}
      onClick={onClick}
      style={sx}
      title={title}
    />
  );
};

ModifiedIcon.defaultProps = {
  size: 30,
  sx: {},
  title: "User Modified",
  className: "",
};

ModifiedIcon.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
  className: PropTypes.string,
  sx: PropTypes.object,
};

export default ModifiedIcon;
