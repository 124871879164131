import trueBlackLogo from "../../img/true/True-Logo-Black-Flat-Copyright.svg";
import trueWhiteLogo from "../../img/true/True-Logo-White-Copyright.svg";
import trueBlackLogoTag from "../../img/true/True-Logo-Black-Flat-Tag-Copyright.svg";

const TrueLogo = ({ size, color, tag }) => {
  const getLogo = () => {
    if (tag) {
      return trueBlackLogoTag;
    } else {
      return color === "white" ? trueWhiteLogo : trueBlackLogo;
    }
  };

  return <img src={getLogo()} alt="TRUE Logo" width={`${size}px`} />;
};

TrueLogo.defaultProps = {
  size: 30,
  color: "white",
  tag: false,
};

export default TrueLogo;
