import BrowserUtils from "./browser.utils";

class FetchError extends Error {
  status;

  constructor(status, message) {
    super(message);
    this.status = status;
    Object.setPrototypeOf(this, FetchError.prototype);
  }
}

const request = async (method, url, data) => {
  const options = {
    // Default options are marked with *
    mode: "cors", // no-cors, *cors, same-origin
    cache: "default", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  };
  options.method = method;

  if (data) {
    options.body = JSON.stringify(data);
  }

  const res = await fetch(url, options);
  if (res.status === 401 && !document.location.pathname.startsWith("/login")) {
    BrowserUtils.localStorage.set(
      "redirect",
      window.location.pathname + window.location.search
    );
    window.location.replace("/login");
    throw new Error("Unauthorized", url);
  }
  const json = await res.json();
  if (res.status >= 400) {
    const msg = json.message ?? "No Message Provided";
    console.error(msg);
    throw new FetchError(res.status, msg);
  }
  return json;
};

class JsonRequest {
  static async get(url) {
    return await request("GET", url, null);
  }
  static async post(url, data) {
    return await request("POST", url, data);
  }
  static async put(url, data) {
    return await request("PUT", url, data);
  }
  static async delete(url) {
    return await request("DELETE", url, null);
  }
}

export default JsonRequest;
