import { IoMdPricetag } from "react-icons/io";

const TagIcon = ({ size, className, title, onClick }) => {
  return (
    <IoMdPricetag
      className={`no-shrink ${className}`}
      size={size}
      title={title}
      onClick={onClick}
    />
  );
};

TagIcon.defaultProps = {
  className: "",
  title: "Tag",
  onClick: () => {},
};

export default TagIcon;
