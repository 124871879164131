class BrowserUtils {
  static storage = {
    get: (type, key) => JSON.parse(type.getItem(key)),
    set: (type, key, item) =>
      type.setItem(
        key,
        JSON.stringify(item, (_key, value) =>
          value instanceof Set ? [...value] : value
        )
      ),
    delete: (type, key) => type.removeItem(key),
  };
  static sessionStorage = {
    get: (key) => BrowserUtils.storage.get(sessionStorage, key),
    set: (key, item) => BrowserUtils.storage.set(sessionStorage, key, item),
    delete: (key) => BrowserUtils.storage.delete(sessionStorage, key),
  };
  static localStorage = {
    get: (key) => BrowserUtils.storage.get(localStorage, key),
    set: (key, item) => BrowserUtils.storage.set(localStorage, key, item),
    delete: (key) => BrowserUtils.storage.delete(localStorage, key),
  };
  static getPageUrl(loanName, pageId) {
    return `/api/trapeze/workflow/batches/${loanName}/files/${pageId}`;
  }
  static getSearchParams() {
    return new URLSearchParams(window.location.search);
  }
  static getServiceParams = (searchParams) => {
    const params = new URLSearchParams(searchParams);
    return {
      id: params.get("id"),
      verifai: params.get("verifai"),
      service: params.get("service"),
      isStandalone: params.has("sa"),
    };
  };
  static isFullScreenPage(pathname) {
    const fullScreenPaths = new Set(["/login", "/login-sso", "/focus"]);
    return fullScreenPaths.has(pathname);
  }
  static downloadObjectAsJson(exportObj, exportName) {
    const dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(exportObj, null, 2));
    const downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }
  static getServiceUrl(id, stage) {
    const optionalQueryParams =
      stage === "DataVerification"
        ? "verifai=multi-service"
        : `service=${stage}`;

    return `/services?id=${id}&${optionalQueryParams}`;
  }

  static getImageUrl(loanId, imageId) {
    return `/api/loans/${loanId}/files/${imageId}`;
  }
}

export default BrowserUtils;
