const serviceReducer = (state, action) => {
  switch (action.type) {
    case "setLoan":
      return { ...state, loan: action.loan };
    case "setService":
      return {
        ...state,
        service: action.service,
        item: null,
        repo: null,
        buttons: null,
        reserved: null,
        explorerFilters: {
          isHidden: () => false,
          buttons: [],
        },
      };
    case "setItem":
      return { ...state, item: action.item };
    case "setRepo":
      return { ...state, repo: action.repo };
    case "setButtons":
      return { ...state, buttons: action.buttons };
    case "setExplorerFilters":
      return { ...state, explorerFilters: action.explorerFilters };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export default serviceReducer;
