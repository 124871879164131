const ExtractionUtils = {
  getVisible: (item) => item.config.visibility === "visible",
  getLowConfFields: (rows) =>
    rows
      .filter((row) => row.type === "fields")
      .flatMap((row) =>
        row.fields.filter((field) => {
          const confidence = field.conf ?? field.confidence;
          return confidence < field.config.threshold;
        })
      ),
  getLowConfSources: (rows) => {
    const sources = ExtractionUtils.getFlattenedSources(rows);
    return sources.filter((field) => {
      const confidence = field.conf ?? field.confidence;
      const threshold = field.threshold ?? field.config.threshold;
      return confidence < threshold;
    });
  },

  getFlattenedFields: (rows) =>
    rows.filter((row) => row.type === "fields").flatMap((row) => row.fields),
  getFlattenedSources: (rows) =>
    rows
      .filter((row) => row.type === "fields" || row.type === "tables")
      .flatMap((row) => {
        if (row.type === "tables") {
          const rows = row.tables[0].rows.flatMap((tableRow, rowIdx) => {
            const cellData = Object.keys(tableRow)
              .filter((column) => column !== "id" && column !== "position")
              .map((column, colIdx) => {
                return {
                  type: "table",
                  tableName: row.tables[0].name,
                  rowId: tableRow.id,
                  ref: row.tables[0].ref,
                  idx: {
                    tableRow: rowIdx,
                    tableCol: colIdx,
                    ...row.tables[0].idx,
                  },
                  config: {
                    visibility: row.tables[0].config.visibility,
                  },
                  ...tableRow[column],
                };
              });

            return cellData;
          });
          return rows;
        }
        return row.fields.map((field) => {
          field.type = "field";
          return { ...field };
        });
      }),
  getTables: (rows) =>
    rows.filter((row) => row.type === "tables").flatMap((row) => row.tables),
  getItemSize: (item) => {
    if (item.visibility === "collapsed") return 0;
    switch (item.type) {
      case "group":
        return 41;
      case "subgroup":
        return 38;
      case "fields":
        if (
          item.fields.filter((field) => field.config.visibility !== "collapsed")
            .length === 0
        )
          return 0;
        return 55.125;
      case "tables":
        const numRows = item.tables[0].rows.length || 1;
        const HEADER_HEIGHT = 56;
        const TABLE_NAME_HEIGHT = 50;
        const ROW_HEIGHT = 52;
        return ROW_HEIGHT * numRows + HEADER_HEIGHT + TABLE_NAME_HEIGHT;
      default:
        return 47.125;
    }
  },
};

export default ExtractionUtils;
