import { FaMinusSquare } from "react-icons/fa";

const MinusIcon = ({ size, className, title, onClick }) => {
  return (
    <FaMinusSquare
      className={className}
      size={size}
      title={title}
      onClick={onClick}
    />
  );
};

MinusIcon.defaultProps = {
  className: "",
  title: "Minus",
  onClick: () => {},
};

export default MinusIcon;
