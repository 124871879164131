import { FaPlusSquare } from "react-icons/fa";

const PlusIcon = ({ size, className, title, onClick, hidden }) => {
  return (
    <FaPlusSquare
      className={className}
      size={size}
      title={title}
      onClick={onClick}
      hidden={hidden}
    />
  );
};

PlusIcon.defaultProps = {
  className: "",
  title: "Plus",
  onClick: () => {},
  size: 30,
  hidden: false,
};

export default PlusIcon;
