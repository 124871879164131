import { VscJson } from "react-icons/vsc";

const LosIcon = ({ size, className, title, onClick }) => {
  return (
    <VscJson
      className={className}
      size={size}
      title={title}
      onClick={onClick}
    />
  );
};

LosIcon.defaultProps = {
  className: "",
  title: "JSON",
  onClick: () => {},
};

export default LosIcon;
