import PropTypes from "prop-types";

const CandyButton = ({ element, onClick, disabled, hidden, className, sx }) => {
  return (
    <button
      className={`candy-button ${className}`}
      style={sx}
      onClick={onClick}
      disabled={disabled}
      hidden={hidden}
    >
      {element}
    </button>
  );
};

CandyButton.defaultProps = {
  disabled: false,
  hidden: false,
  className: "secondary-color-scheme",
  sx: {},
  onClick: () => {},
};

CandyButton.propTypes = {
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  element: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  sx: PropTypes.object,
};

export default CandyButton;
