import PropTypes from "prop-types";
import { MdOutlineRateReview } from "react-icons/md";

const AuditIcon = ({ onClick, size, sx, className }) => {
  return (
    <MdOutlineRateReview
      className={`icon ${className}`}
      size={size}
      onClick={onClick}
      style={sx}
      title="Audit"
    />
  );
};

AuditIcon.defaultProps = {
  size: 30,
  sx: {},
  onClick: () => {},
  className: "",
};

AuditIcon.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
  className: PropTypes.string,
};

export default AuditIcon;
