import { MdDelete } from "react-icons/md";
import PropTypes from "prop-types";

const DeleteIcon = ({
  onClick,
  size,
  sx,
  className,
  title,
  hidden,
  disabled,
}) => {
  return (
    <MdDelete
      className={`${className} no-shrink`}
      size={size}
      onClick={onClick}
      style={sx}
      title={title}
      hidden={hidden}
      disabled={disabled}
    />
  );
};

DeleteIcon.defaultProps = {
  size: 30,
  onClick: () => {},
  sx: {},
  className: "",
  title: "Delete",
  hidden: false,
  disabled: false,
};

DeleteIcon.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
  sx: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string,
  hidden: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default DeleteIcon;
