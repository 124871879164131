import { GrDrag } from "react-icons/gr";
import PropTypes from "prop-types";

const DragIcon = ({ onClick, size, sx }) => {
  return <GrDrag size={size} onClick={onClick} style={sx} />;
};

DragIcon.defaultProps = {
  size: 30,
  onClick: () => {},
  sx: {},
};

DragIcon.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
};
export default DragIcon;
