import { useState, useCallback } from "react";
import DropZone from "./DropZone";
import Thumbnail from "./Thumbnail";
import BrowseConstants from "../../store/browse.constants";

const Page = ({ _this, onDrop, onClick }) => {
  const [direction, setDirection] = useState("center");
  const alt = `page-${_this.index + 1}`;

  const onHover = useCallback((dropZone) => {
    const direction = dropZone.position === "left" ? "right" : "left";
    setDirection(direction);
  }, []);

  const offHover = useCallback(() => setDirection("center"), []);

  const getThumbnailStyle = useCallback((direction) => {
    const translationSize = 15;
    const translate =
      direction === "right"
        ? `translateX(${translationSize}px)`
        : direction === "left"
        ? `translateX(${-translationSize}px)`
        : "translateX(0px)";

    return {
      transition: `transform 0.3s ease`,
      transform: translate,
    };
  }, []);

  return (
    <div
      className="flex"
      style={{ margin: `5px -${BrowseConstants.OVERLAY_DROPZONE_WIDTH}px` }}
    >
      <DropZone
        item={{
          ..._this,
          position: "left",
        }}
        onHover={onHover}
        offHover={offHover}
        onDrop={onDrop}
        style={{
          position: "relative",
          left: `${BrowseConstants.OVERLAY_DROPZONE_WIDTH}px`,
          zIndex: 10,
        }}
      />
      <Thumbnail
        className={"grab box-shadow"}
        _this={_this}
        src={_this.src}
        alt={alt}
        style={getThumbnailStyle(direction)}
        onClick={onClick}
      />
      <DropZone
        item={{
          ..._this,
          position: "right",
        }}
        onHover={onHover}
        offHover={offHover}
        onDrop={onDrop}
        style={{
          position: "relative",
          right: `${BrowseConstants.OVERLAY_DROPZONE_WIDTH}px`,
          zIndex: 10,
        }}
      />
    </div>
  );
};

export default Page;
