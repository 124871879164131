import { useCallback } from "react";
import AutocompleteField from "./AutocompleteField";
import StandardField from "./StandardField";
import ExtractionTable from "./ExtractionTable";

const checkbox = { options: ["0", "1"] };

const ExtractionRow = ({ data, index, style }) => {
  // console.log("extraction row");

  const getFieldWidth = useCallback((fields) => {
    const FIELD_WIDTH = 530;
    if (fields.length === 1) return FIELD_WIDTH / 2 + "px";
    return FIELD_WIDTH / fields.length + "px";
  }, []);

  const Row = (row) => {
    if (row.visibility === "collapsed") return null;
    switch (row.type) {
      case "group":
        return <h1 className="font-18 pad-5">{row.label}</h1>;
      case "subgroup":
        return <h2 className="font-18 pad-5">{row.label}</h2>;
      case "fields":
        const fields = row.fields.filter(
          (field) => field.config.visibility !== "collapsed"
        );
        return (
          <div className="flex">
            {fields.map((field, idx) => {
              if (field.name === "Version") {
                return null;
              }
              switch (field.config.inputElement) {
                case "autocomplete":
                  return (
                    <AutocompleteField
                      key={idx}
                      field={field}
                      options={field.config.options}
                      onChange={data.onChange}
                      onFocus={data.onFocus}
                      addRef={data.addRef}
                      width={getFieldWidth(row.fields)}
                    />
                  );
                case "checkbox":
                  return (
                    <AutocompleteField
                      key={idx}
                      field={field}
                      options={checkbox.options}
                      onChange={data.onChange}
                      onFocus={data.onFocus}
                      addRef={data.addRef}
                      width={getFieldWidth(row.fields)}
                    />
                  );
                default:
                  return (
                    <StandardField
                      key={idx}
                      field={field}
                      onChange={data.onChange}
                      onFocus={data.onFocus}
                      addRef={data.addRef}
                      width={getFieldWidth(row.fields)}
                    />
                  );
              }
            })}
          </div>
        );
      case "tables":
        return (
          <ExtractionTable
            table={row.tables[0]}
            onFocus={data.onFocus}
            onChange={data.onTableChange}
            onRowUpdate={data.onTableRowUpdate}
          />
        );
      default:
        return null;
    }
  };

  return <div style={style}>{Row(data.rows[index])}</div>;
};

export default ExtractionRow;
