import MathUtils from "../../../utils/math.utils";
import StringUtils from "../../../utils/string.utils";

const TableConfig = {
  docs: [
    {
      id: "type",
      label: "Type",
      action: "view",
    },
    {
      id: "_id",
      label: "ID",
      type: "string",
      function: (id) => id.slice(18, 24),
    },
    {
      id: "used",
      label: "Used",
      function: (used) => (used ? "Yes" : "No"),
    },
  ],
  incomeTypes: [
    {
      id: "name",
      label: "Name",
      footer: () => "Total",
      function: (name) => StringUtils.toTitleCase(name),
    },
    {
      id: "value",
      label: "Value",
      function: (num) => StringUtils.formatUsd(num),
      footer: (data) => StringUtils.formatUsd(MathUtils.sum(data)),
    },
    {
      id: "stated",
      label: "Stated",
      function: (num) => StringUtils.formatUsd(num),
    },
    {
      id: "error",
      label: "Error",
    },
  ],
  elements: [
    {
      id: "docType",
      label: "Document",
      function: (docType) => (docType ? docType : "N/A"),
    },
    {
      id: "docId",
      label: "ID",
      type: "string",
      function: (id) => (id.length === 24 ? id.slice(18, 24) : "N/A"),
    },
    {
      id: "fieldName",
      label: "Name",
      action: "view",
      function: (fieldName) => (fieldName ? fieldName : "N/A"),
    },
    {
      id: "value",
      label: "Value",
      function: (value) => (value ? value : "Not Found"),
    },
    {
      id: "fieldConf",
      label: "Conf",
      function: (fieldConf) => (fieldConf ? fieldConf : 0),
    },
  ],
  alertsAndWarnings: [
    {
      id: "name",
      label: "Name",
    },
    {
      id: "result",
      label: "Result",
      function: (result) => (result ? "Passed" : "Failed"),
    },
    {
      id: "message",
      label: "Error",
    },
  ],
  verified: [
    {
      id: "name",
      label: "Name",
    },
    {
      id: "result",
      label: "Result",
      function: (result) => (result ? "Passed" : "Failed"),
    },
  ],
};

export default TableConfig;
