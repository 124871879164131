const LoanTableConfig = [
  {
    field: "services",
    headerName: "Services",
    type: "buttons",
  },
  {
    field: "name",
    headerName: "Name",
    type: "string",
  },
  {
    field: "activeJobs",
    headerName: "Active Jobs",
    type: "number",
  },
  {
    field: "dateAdded",
    headerName: "Date Added",
    type: "date",
  },
  {
    field: "dateModified",
    headerName: "Date Modified",
    type: "date",
  },
  {
    field: "delete",
    headerName: "Delete",
    type: "buttons",
  },
];
export default LoanTableConfig;
