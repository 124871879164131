import { AiOutlineDown } from "react-icons/ai";
import PropTypes from "prop-types";

const DownIcon = ({ onClick, size, sx, className }) => {
  return (
    <AiOutlineDown
      className={`icon ${className}`}
      size={size}
      onClick={onClick}
      style={sx}
    />
  );
};

DownIcon.defaultProps = {
  size: 30,
  onClick: () => {},
  sx: {},
  className: "",
};

DownIcon.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
  sx: PropTypes.object,
  className: PropTypes.string,
};
export default DownIcon;
