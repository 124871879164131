import React from "react";

const Block = ({ className }) => {
  return (
    <div className={className} style={{ width: "15px", height: "15px" }} />
  );
};

Block.defaultProps = {
  className: "",
};

export default Block;
