import { useState, forwardRef } from "react";
import DocumentIcon from "../Icons/DocumentIcon";

const Document = forwardRef(
  ({ _this, index, selectedIndex, onClick, hidden }, ref) => {
    const selected =
      index.outer === selectedIndex.outer &&
      index.inner === selectedIndex.inner;
    const [hover, setHover] = useState(false);
    const size = 30;

    const getBgColor = () => (selected || hover ? "bg-lg" : "");

    const getColor = () => (_this.confidence < _this.threshold ? "red" : "");

    return (
      <div
        ref={ref}
        hidden={hidden}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => onClick(_this, index)}
        className={`flex align-center gap-5 pointer pad-5 ${getBgColor()} ${getColor()}`}
        title={_this.explorer.name}
      >
        {_this.explorer.icon || <DocumentIcon size={size} />}
        <div className="ellipses">
          <div className="ellipses font-14">{_this.explorer.name}</div>
          {_this.explorer.tag && (
            <div className="ellipses">{_this.explorer.tag}</div>
          )}
        </div>
      </div>
    );
  }
);

Document.defaultProps = {
  name: "Folder",
  hidden: false,
  onClick: () => {},
  selectedIndex: { outer: -1, inner: -1 },
  _this: {},
};

export default Document;
