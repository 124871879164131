import { IoCheckmarkCircleSharp } from "react-icons/io5";

const CheckmarkIcon = ({ size, className, title, onClick }) => {
  return (
    <IoCheckmarkCircleSharp
      className={className}
      size={size}
      title={title}
      onClick={onClick}
    />
  );
};

CheckmarkIcon.defaultProps = {
  className: "",
  title: "Check",
  onClick: () => {},
};

export default CheckmarkIcon;
