class MathUtils {
  static sum(columnData) {
    return columnData.reduce((partialSum, a) => partialSum + a, 0);
  }
  static sumProperty(columnData, property) {
    return columnData.reduce((partialSum, a) => partialSum + a[property], 0);
  }
}

export default MathUtils;
