import { GrCertificate } from "react-icons/gr";
import PropTypes from "prop-types";

const CrossValIcon = ({ onClick, size, sx, className, title }) => {
  return (
    <GrCertificate
      className={`icon ${className}`}
      size={size}
      onClick={onClick}
      style={sx}
      title={title}
    />
  );
};

CrossValIcon.defaultProps = {
  size: 30,
  sx: {},
  title: "Validated",
};

CrossValIcon.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
  sx: PropTypes.object,
  className: PropTypes.string,
};

export default CrossValIcon;
