import PropTypes from "prop-types";
import StandardAttribute from "../Attributes/StandardAttribute";
import AlertIcon from "../Icons/AlertIcon";

const Confidence = ({ confidence, threshold, className, sx }) => {
  return (
    <div className={`pad-5 br-5 font-14 ${className}`} style={sx}>
      <StandardAttribute label="Confidence" data={`${confidence}%`}>
        {confidence < threshold && <AlertIcon size={20} />}
      </StandardAttribute>
    </div>
  );
};

Confidence.propTypes = {
  confidence: PropTypes.number,
  threshold: PropTypes.number,
  className: PropTypes.string,
};

Confidence.defaultProps = {
  className: "",
  sx: {},
};

export default Confidence;
