import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./pages/Login/Login";
import NavBar from "./components/NavBar/NavBar";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { AppContext } from "./Contexts/Contexts";
import Alert from "./components/Alerts/Alert";
import Blocker from "./components/Alerts/Blocker";
import CheckmarkIcon from "./components/Icons/CheckmarkIcon";
import Confirm from "./components/Alerts/Confirm";
import _default from "./store/default-props";
import Footer from "./components/Footers/Footer";
import LeftPopOut from "./components/Popups/LeftPopOut";
import Slide from "./components/Animations/Slide";
import DiamondBg from "./img/backgrounds/diamond-background.svg";
import ApiClient from "./clients/api.client";
import Sandbox from "./devtools/Sandbox";
import CircleLoader from "./components/Loaders/CircleLoader";
import LoanDashboard from "./pages/Loans/LoanDashboard";
import Focus from "./pages/Focus/Focus";
import LoanContainer from "./pages/Services/LoanContainer";
import { sleep } from "./utils/general.utils";
import BrowserUtils from "./utils/browser.utils";
import { useQuery } from "@tanstack/react-query";

const App = () => {
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [dim, setDim] = useState("");
  const [loaderProps, setLoaderProps] = useState(_default.loader);
  const [checkmark, setCheckmark] = useState(false);
  const [leftPopOutProps, setLeftPopOutProps] = useState(_default.leftPopOut);
  const [alertProps, setAlertProps] = useState(_default.alert);
  const [blockerProps, setBlockerProps] = useState(_default.blocker);
  const [confirmProps, setConfirmProps] = useState(_default.confirm);
  const [onUnloadProps, setOnUnloadProps] = useState(_default.onUnload);

  // Global Functions
  const onUnloadSet = useCallback((callback, path) => {
    setOnUnloadProps({ callback: callback, path: path });
  }, []);

  const onUnloadClear = useCallback(() => {
    setOnUnloadProps(_default.onUnload);
  }, []);

  const showCheckmark = useCallback(async () => {
    setCheckmark(true);
    await sleep(1.5);
    setCheckmark(false);
  }, []);

  const loader = useMemo(() => {
    return {
      open: (content = null) => {
        setLoaderProps({
          content: content,
          hide: false,
        });
      },
      close: () => setLoaderProps(_default.loader),
    };
  }, []);

  const leftPopOutOpen = useCallback(
    (header, content) =>
      setLeftPopOutProps({
        header: header,
        content: content,
        hide: false,
      }),
    []
  );

  const leftPopOutClose = useCallback(
    () => setLeftPopOutProps(_default.popup),
    []
  );

  const blockerOpen = useCallback(
    (content) => setBlockerProps({ content: content, hide: false }),
    []
  );

  const blockerClose = useCallback(() => setBlockerProps(_default.blocker), []);

  const alertOpen = useCallback(
    (content, onAccept = () => {}) =>
      setAlertProps({ content: content, onAccept: onAccept, hide: false }),
    []
  );

  const alertClose = useCallback(() => setAlertProps(_default.alert), []);

  const confirmOpen = useCallback(
    (content, onAccept) =>
      setConfirmProps({
        content: content,
        onAccept: onAccept,
        hide: false,
      }),
    []
  );

  const confirmClose = useCallback(() => setConfirmProps(_default.confirm), []);

  // get-setters
  const getSetUser = useCallback(async () => {
    const user = await ApiClient.getUser();
    setUser(user);
  }, []);

  const { data: tenants } = useQuery({
    queryKey: ["tenants"],
    queryFn: async () => await ApiClient.getTenants(),
  });

  const { data: workflows } = useQuery({
    queryKey: ["workflows"],
    queryFn: async () => await ApiClient.getWorkflows(),
    enabled: !!user,
  });

  // useEffects

  // getUser
  useEffect(() => {
    if (
      user ||
      location.pathname.startsWith("/login") ||
      location.pathname.startsWith("/api")
    )
      return;
    getSetUser();
    const id = setInterval(async () => {
      if (document.hidden) return;
      await ApiClient.refresh();
    }, 60 * 1000);
    return () => clearInterval(id);
  }, [location, user, getSetUser]);

  // toggle dim
  useEffect(() => {
    !loaderProps.hide ||
    !leftPopOutProps.hide ||
    !blockerProps.hide ||
    !alertProps.hide ||
    checkmark ||
    !confirmProps.hide
      ? setDim("dim")
      : setDim("");
  }, [
    loaderProps,
    leftPopOutProps,
    alertProps,
    checkmark,
    confirmProps,
    blockerProps,
  ]);

  // runOnUnload
  useEffect(() => {
    if (!onUnloadProps.callback || onUnloadProps.path === location.pathname)
      return;
    onUnloadProps.callback();
    onUnloadClear();
  }, [onUnloadProps, onUnloadClear, location]);

  // cleanup
  useEffect(() => {
    const cleanup = () => {
      loader.close();
      setBlockerProps(_default.blocker);
      setAlertProps(_default.alert);
      setConfirmProps(_default.confirm);
      setLeftPopOutProps(_default.leftPopOut);
    };
    cleanup();
  }, [location.pathname, loader]);

  useEffect(() => {
    const stopFileSave = (e) => {
      if (e.ctrlKey && e.key === "s") e.preventDefault();
    };
    window.addEventListener("keydown", stopFileSave, false);
    return () => window.removeEventListener("keydown", stopFileSave, false);
  }, []);

  return (
    <AppContext.Provider
      value={{
        tenants,
        workflows,
        user,
        setUser,
        loader,
        leftPopOut: { open: leftPopOutOpen, close: leftPopOutClose },
        blocker: { open: blockerOpen, close: blockerClose },
        alert: { open: alertOpen, close: alertClose },
        confirm: { open: confirmOpen, close: confirmClose },
        showCheckmark,
        onUnload: { set: onUnloadSet, clear: onUnloadClear },
      }}
    >
      <div
        id="viewport"
        className={`${dim}`}
        style={{
          backgroundImage: `url(${DiamondBg})`,
        }}
      >
        {BrowserUtils.isFullScreenPage(location.pathname) || <NavBar />}
        <div className="main-content-container">
          {
            <Routes>
              <Route path="" element={<LoanDashboard />} />
              <Route path="login" element={<Login />} />
              {/* <Route path="login-sso" element={<LoginSso />} /> */}
              <Route path="loan" element={<LoanContainer />} />
              <Route path="loans" element={<LoanDashboard />} />
              {/* <Route exact path="/docs/api" element={<APIDocumentation />} /> */}
              {/* <Route path={"admin"} element={<Admin />} /> */}
              {/* <Route path={"admin/config"} element={<CustomConfig />} /> */}
              <Route path={"sandbox"} element={<Sandbox />} />
              <Route path={"focus"} element={<Focus />} />
            </Routes>
          }
        </div>
        {BrowserUtils.isFullScreenPage(location.pathname) || <Footer />}
      </div>
      <Slide
        className="left-pop-out-container"
        isVisible={!leftPopOutProps?.hide}
        children={
          !leftPopOutProps?.hide && (
            <LeftPopOut leftPopOutProps={leftPopOutProps} />
          )
        }
      />
      <div className="center-stage">
        <div style={{ backgroundColor: "white" }}></div>
        {!loaderProps.hide && <CircleLoader loaderProps={loaderProps} />}
        {!blockerProps.hide && <Blocker blockerProps={blockerProps} />}
        {!alertProps.hide && <Alert alertProps={alertProps} />}
        {!confirmProps.hide && <Confirm confirmProps={confirmProps} />}
        {checkmark && (
          <CheckmarkIcon className="bg-black green br-half" size={140} />
        )}
      </div>
    </AppContext.Provider>
  );
};

export default App;
