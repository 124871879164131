import PropTypes from "prop-types";
import { CgFileDocument } from "react-icons/cg";

const ClassificationIcon = ({ size, className, onClick, title }) => {
  return (
    <CgFileDocument
      className={`no-shrink  ${className}`}
      size={size}
      title={title}
      onClick={onClick}
    />
  );
};

ClassificationIcon.defaultProps = {
  size: 30,
  className: "",
  onClick: () => {},
  title: "Classification",
};

ClassificationIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

export default ClassificationIcon;
