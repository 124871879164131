import { useContext, useEffect, useRef } from "react";

import { AppContext } from "../../Contexts/Contexts";
import CircleCloseIcon from "../Icons/CircleCloseIcon";
import PropTypes from "prop-types";

const LeftPopOut = ({ leftPopOutProps }) => {
  const { leftPopOut } = useContext(AppContext);
  const ref = useRef(null);

  useEffect(() => {
    const hotClicks = async (e) => {
      const bounds = ref.current?.getBoundingClientRect();
      if (
        bounds &&
        (e.clientX < bounds.left ||
          e.clientX > bounds.right ||
          e.clientY < bounds.top ||
          e.clientY > bounds.bottom)
      ) {
        leftPopOut.close();
      }
    };
    window.addEventListener("mousedown", hotClicks);
    return () => window.removeEventListener("mousedown", hotClicks);
  }, [leftPopOut]);

  useEffect(() => {
    const hotkeys = (e) => {
      if (e.key === "Escape") {
        leftPopOut.close();
      }
    };
    window.addEventListener("keydown", hotkeys);
    return () => window.removeEventListener("keydown", hotkeys);
  }, [leftPopOut]);

  return (
    <div className="flex-col h-full max-w-full-screen" ref={ref}>
      <div className="flex space-between algin-center pad-5-10 gap-10 overflow-hidden">
        <div>{leftPopOutProps.header}</div>
        <CircleCloseIcon
          onClick={() => leftPopOut.close()}
          size={30}
          className={"button"}
        />
      </div>
      <div className="h-full overflow-hidden pad-5-10 flex justify-center">
        {leftPopOutProps.content}
      </div>
    </div>
  );
};

LeftPopOut.propTypes = {
  popOutProps: PropTypes.object,
};

export default LeftPopOut;
