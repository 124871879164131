import React from "react";
import { TbReportSearch } from "react-icons/tb";

const ReportIcon = ({ onClick, size, sx, className, title }) => {
  return (
    <TbReportSearch
      className={`icon ${className}`}
      onClick={onClick}
      size={size}
      style={sx}
      color="white"
      title={title}
    />
  );
};

ReportIcon.defaultProps = {
  size: 30,
  sx: {},
  title: "Report",
  className: "",
};

export default ReportIcon;
