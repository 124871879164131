import { useState, useContext, useEffect } from "react";
import CandyButton from "../../components/Buttons/CandyButton";
import { AppContext } from "../../Contexts/Contexts";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../clients/api.client";
import TrueLogo from "../../components/Icons/TrueLogo";
import { TextField, Autocomplete } from "@mui/material";
import textFieldProps from "../../store/textfield.props";
import loginBg from "../../img/backgrounds/login-bg-3.png";
import BrowserUtils from "../../utils/browser.utils";

const Login = () => {
  const navigate = useNavigate();
  const { tenants, user, setUser, alert, loader } = useContext(AppContext);
  const [tenant, setTenant] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const login = async (e) => {
    e.preventDefault();
    if (!tenant || !username || !password) {
      alert.open(<div>{`Required Fields Missing`}</div>);
      return;
    }
    try {
      loader.open();
      const user = await ApiClient.login(tenant._id, username, password);
      setUser(user);
    } catch (err) {
      alert.open(<div>{err.toString()}</div>);
      setPassword("");
      return;
    } finally {
      loader.close();
    }
    const path = BrowserUtils.localStorage.get("redirect") || "";
    BrowserUtils.localStorage.delete("redirect");
    navigate(path);
  };

  useEffect(() => {
    if (user) navigate("/loans");
  }, [user, navigate]);

  return (
    <div
      className="s-full flex-center"
      style={{
        background: `url(${loginBg}) left center / contain no-repeat, black`,
      }}
    >
      <div className="w-half" />
      <div
        className="border-top-green pad-20 flex-col align-center gap-20 bg-white"
        style={{ width: "350px" }}
      >
        <TrueLogo tag size={300} />
        <form onSubmit={login} className="flex-col gap-20 s-full">
          <Autocomplete
            size="small"
            options={tenants ?? []}
            autoComplete
            autoHighlight
            value={tenant}
            onChange={(e, value) => setTenant(value)}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={"Tenant"}
                InputLabelProps={{
                  shrink: true,
                  style: { color: "#4A4A4A", fontSize: "16px" },
                }}
                sx={{
                  "& .MuiInputBase-input": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: {
                    fontSize: "14px",
                    color: "black",
                    backgroundColor: "white",
                  },
                }}
              />
            )}
          />
          <TextField
            variant={"outlined"}
            label={"Username"}
            value={username}
            size="small"
            onChange={(e) => setUsername(e.target.value)}
            InputLabelProps={{ style: { color: "black" }, shrink: true }}
            inputProps={{
              style: textFieldProps,
            }}
          />
          <TextField
            type="password"
            variant={"outlined"}
            label={"Password"}
            value={password}
            size="small"
            onChange={(e) => setPassword(e.target.value)}
            InputLabelProps={{ style: { color: "black" }, shrink: true }}
            inputProps={{
              style: textFieldProps,
            }}
          />
          <CandyButton
            className="secondary-color-scheme"
            element={<input type="submit" value="Log In" onClick={login} />}
          />
        </form>
        <div className="separator sans bold w-full"> or </div>
        <CandyButton
          className="secondary-color-scheme"
          element={<div>Sign On With Identity Provider</div>}
          onClick={() => (window.location.href = "/api/auth/microsoft/login")}
        />
      </div>
    </div>
  );
};

export default Login;
