import { useDrop } from "react-dnd";
import BrowseConstants from "../../store/browse.constants";
import { useRef } from "react";

const DropZone = ({
  item,
  onDrop: __onDrop,
  onHover,
  offHover,
  style,
  className,
}) => {
  const ref = useRef(null);

  const onDrop = (droppedItem) => {
    offHover();
    __onDrop(droppedItem, item);
  };

  const [, drop] = useDrop({
    accept: item.type,
    drop: onDrop,
  });

  drop(ref);

  return (
    <div
      className={className}
      ref={ref}
      style={{
        width: `${BrowseConstants.DROPZONE_WIDTH}px`,
        height: `${BrowseConstants.THUMBNAIL_HEIGHT}px`,
        transition: "background-color 0.3s ease",
        ...style,
      }}
      onDragEnter={() => onHover(item)}
      onDragLeave={offHover}
    />
  );
};

DropZone.defaultProps = {
  onHover: () => {},
  offHover: () => {},
  onDrop: () => {},
  style: {},
  className: "",
};

export default DropZone;
