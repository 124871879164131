import PropTypes from "prop-types";
import React from "react";

const HorizontalDivider = () => {
  return <div className="horizontal-divider" />;
};

const VerticalIconBar = ({ icons, className }) => {
  return (
    <div className={`flex-col bg-lg align-center h-full ${className}`}>
      {icons.map((icon, idx) => {
        return (
          <React.Fragment key={idx}>
            {icon}
            <HorizontalDivider />
          </React.Fragment>
        );
      })}
    </div>
  );
};

VerticalIconBar.defaultProps = {
  icons: [],
  className: "",
};

VerticalIconBar.propTypes = {
  icons: PropTypes.array,
};

export default VerticalIconBar;
